import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Client } from 'src/app/models/client';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { ClientService } from 'src/app/services/client.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Store } from 'src/app/models/store';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { StoreService } from 'src/app/services/store.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { Router } from '@angular/router';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { UtilsService } from 'src/app/services/utils.service';
import swal from "sweetalert2";

interface InfoFees {
  amountFees;
  dueDate;
  due: any[];
}

@Component({
  selector: 'app-inventory-exchange-bill',
  templateUrl: './inventory-exchange-bill.component.html',
  styleUrls: ['./inventory-exchange-bill.component.scss'],
  providers: [UtilsService, ClientService, InventoryService, InvoiceService, StoreService, CompanyService, WarehouseService],
})
export class InventoryExchangeBillComponent implements OnInit {
  // Variables auxiliares
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  infoFees = {} as InfoFees; //public transferDetail: any[];
  amountFees;
  dateFees;
  date;
  public storeName: string;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  public isNailCenter: boolean;
  public temp = [];
  loading;

  public isBeginning: boolean;
  public isBilling: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false;
  public calculateTrue: boolean = false;
  public activeRow: any;

  //FACTURAR DESDE CLIENT
  public code: number = 0;
  public url: any;
  // activatedRoute: ActivatedRoute | null | undefined;

  //FACTURA DESDE TRANSFERENCIA
  public isParamsUrl: boolean = false;
  public transferCode: any;
  public transferDetail: any[];
  public total: number;

  // DATA SELECT
  public id: number;
  public itemName: string;
  public selectedItems = [];
  public selectedItemsSerial = [];
  public inventoryList: any[] = new Array();
  public serialList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  public dropdownSettingsSerial: IDropdownSettings = {};
  closeDropdownSelection = false;
  closeDropdownSelectionSerial = false;
  public serialSelected = new Array;
  public serialSelectedTemp = new Array;
  public dropdownSettingsProduct = {};

  // Variables de Linea de Compra
  public Minstock: number;
  public itemCode: string;
  public stock: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;
  public priceType: number;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;
  public tipoPago = 0;
  public OrdenCompra = "0";
  public tipoCliente = 0;

  public entries: number = 10;

   // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledInvoice = false;
  public isMethodPay = false;
  public companyAddDescription: number;
  public companyExentaInvoice: number;
  public ExentaInvoice: number | boolean;

  public specialFields = false;
  public refInternSol = "0";
  public codeSol = "";
  public salePersonSol = "";
  public measurementSol = 0;
  public measurementSolValue = "";

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private _storeService: StoreService,
    private _utilsService: UtilsService,
    private _clientService: ClientService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _inventoryService: InventoryService,
    private _warehouseService: WarehouseService
  ) { }

  async ngOnInit() {
    this.isNailCenter = false;
    this.addAmount = true;
    this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.moneda = 'Q';
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceHeader.paymentType = 0;
    this.invoiceHeader.invoiceExchangeRate = 0;
    this.tipoPago = 0;

    // DATE
    let day: any;
    let mount: any;
    let date: Date = new Date();

    date.getDate() < 9 ? (day = '0' + date.getDate()) : (day = date.getDate());
    date.getMonth() < 9 ? (mount = '0' + (date.getMonth() + 1)) : (mount = date.getMonth() + 1);
    let year = date.getFullYear();
    
    // this.invoiceHeader.invoiceDate = year + '-' + mount + '-' + day;
    // this.nowDay = this.invoiceHeader.invoiceDate;
    // this.invoiceExchangeDate = this.nowDay;

    this.nowDay = this._utilsService.dateTime('yyy-MM-dd');
    this.invoiceHeader.invoiceDate = this.nowDay;

    this.priceType = 1;
    this.invoiceExchangeNumber = 1;
    this.invoiceExchangeAmount = 0;

    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    const _user = JSON.parse(localStorage.getItem('user'));
    this.invoiceHeader.invoiceInternSerial = _user.userSerial ? _user.userSerial.toString() : 'A';

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    await this.getInventoryList(this.storeCode);
    this.getSelectSetting(this.selectedItems);
    this.getSelectSettingSerial(this.selectedItemsSerial);
    this.getSelectSettingPaymet(this.selectedItemsPayment);

    console.log('Select -> ', this.selectedItems);
    console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    console.log('Tipo pagos -> ', this.PaymentList);

    this.Pagado();

    this.SKUx = "";
    this.client.clientType = 0;
    this.disabledEXT = true;
    console.log(this.invoiceHeader.invoiceCurrency);

    
    if(this.companyCode == 22){
      this.isNailCenter = true;
      this.getAll(48);
    }

    this.url = this.router.parseUrl(this.router.url);
    if (this.url.queryParams['nit'] != undefined) {

      if (localStorage.getItem('clientData') !== null) {
        console.log('si hay data de cliente');

        let _dataCli = JSON.parse(localStorage.getItem('clientData'));

        console.log('localstorage -> ', _dataCli.clientName);

        this.client.clientName = _dataCli.clientName;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientPhone = _dataCli.clientPhone;
        this.client.clientEmail = _dataCli.clientEmail;
        this.client.clientAddress = _dataCli.clientAddress;
        this.client.clientCode =  _dataCli.clientCode;
        this.client.clientTaxDocument = _dataCli.clientTaxDocument;        
        this.tipoCliente = _dataCli.clientType;
        this.client.clientType = _dataCli.clientType;

        this.viewBilling();
        this.loading = false;
        this.isDisabled =false;

        console.log('Cliente -> ', this.client);
        console.log('Tipo Cliente -> ', this.tipoCliente);
  
        this.insertNewClient = false;
        this.recurrentClient = true;
      }

      // this.isParamsUrl = true;
      // this.code = this.url.queryParams['nit'];
      // await this.buscarNITLocal(event, this.code);

      if (this.url.queryParams['transfer']) {
        let transfer = this.url.queryParams['transfer'];
        await this.cargarTrasnferencia(transfer);
      }

    }
    
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
    
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.inventoryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  /////////////////////AGREGAR LINEA DESCRIPCION AL DETALLE FACTURA///////////////////////////
  saveLineInvoiceDesc() {
    this.invoiceDetail.invoiceDetailDiscount = 0;
    if (this.invoiceDetail.invoiceDetailDescription != "" && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      // if (this.itemCode == "I") {
      //   this.itemNumberCode++;
      //   this.itemCode += this.itemNumberCode;
      // }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

      if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
      }

      //Actualizar
      // this.invoiceLine.forEach((e) => {
      //   console.log('actualiza descripcion');
      //   if (e.itemCode == this.itemCode) {

      //     this.invoiceHeader.invoiceTotal -= e.total;

      //     e.itemCode = 0;
      //     e.measure = "UNI";
      //     e.taxCode = 1;
      //     e.taxName = "IVA";
      //     e.isService = this.invoiceDetail.invoiceDetailType;
      //     e.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6));
      //     e.description = this.invoiceDetail.invoiceDetailDescription;
      //     e.unitPrice = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
      //     e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
      //     e.discount = parseFloat((this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
      //     e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
      //     e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
      //     e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));

      //     // this.total = this.total + this.subTotal;
      //     this.addAmount = false;
      //   }
      // })

      //Insertar
      if (this.addAmount == true) {
        console.log('Inserta descripcion');
        this.invoiceLine.push({
          // subsidio: this.subsidioTemp,
          // isOil: this.isOil,
          taxOilUP: 0,
          // taxOilAmount: this.subIdp,
          // taxOilName: this.petroleo,
          // taxOilCode: this.taxOilCode,
          taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,          
          itemCode: 999999,//this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: "B",
          quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4)),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(4)),
          discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4))
        });
      }
      console.log('Linea',this.invoiceLine);
      // Sumar Total
      this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }

    this.ValidarCF();
    this.Pagado();

    this.isDisabled = false;
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice);

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this._utilsService.showNotification(2, "Cantidad No permitida");
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;
    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(6);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

  public SKUx: string;
  async CargarSKU() {
    var sku;
    sku = this.SKUx;
    //alert(sku);

    if(sku.length > 3){
      const _selectedItem = await this.inventoryList.find(x => x.id == sku);

      //SI ES PETROLIO
      // this.idp = 0;
      // this.subIdp = 0;
      // this.subsidioTemp = 0;
      // this.isOil = null;
      // this.petroleo = null;
      // if (_selectedItem.inventoryProduct == 3) {
      //   this.isOil = 1
      //   this.idp = 4.7;
      //   this.taxOilCode = 1;
      //   this.petroleo = "PETROLEO";
      // } else if (_selectedItem.inventoryProduct == 4) {
      //   this.idp = 4.6;
      //   this.subsidioTemp = 2.5;
      //   this.taxOilCode = 2;
      //   this.petroleo = "PETROLEO";
      // } else if (_selectedItem.inventoryProduct == 5) {
      //   this.idp = 1.3;
      //   this.subsidioTemp = 5;
      //   this.taxOilCode = 4;
      //   this.petroleo = "PETROLEO";
      // }

      this.itemCode = sku;
      this.invoiceDetail.invoiceDetailDescription = _selectedItem.itemName;
      this.invoiceDetail.invoiceDetailType = 'B';
      this.stock = _selectedItem.inventoryQuantity;

      if (_selectedItem.inventorySerialFlag == 1) {
        this.loading = true;
        this.serialSelectedTemp = [];
        this.serialList = [];
        const response = await this._inventoryService.getItemSerial(this.itemCode);
        this.serialList = response.records[1];

        this.serial = true;
        this.loading = false;
      } else {
        this.serial = false
      }

      await this.saveLineInvoice();
      this.SKUx = "";      
    }
    
  }

  onChangepaymentType(event) {
    this.invoiceHeader.paymentType = event;
    this.tipoPago = event;
    console.log(this.tipoPago);
  }

  //////TRANSFER
  public jumpSerial: boolean;
  async cargarTrasnferencia(code) {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    await this._warehouseService.getOneTransfer(code).then(
      response => {
        this.transferCode = response.records[0].transferCode;
        this.transferDetail = response.records[0].detail;
      });

    for (const e of this.transferDetail) {
      const _selectedItem = await this.inventoryList.find(x => x.id == parseFloat(e.transferInventoryDetailInventoryCode));
      this.invoiceDetail.invoiceDetailType = 'B';
      this.itemCode = e.transferInventoryDetailInventoryCode;
      this.invoiceDetail.invoiceDetailQuantity = parseFloat(e.transferInventoryDetailQuantity);
      this.invoiceDetail.invoiceDetailDescription = _selectedItem.inventoryBusinessCode + ' - ' + e.transferInventoryDetailDescription;
      this.stock = _selectedItem.inventoryQuantity;
      this.priceType = 1;

      // Nuevo
      if (parseFloat(e.transferDetailType) === 1) {
        await this.saveLineInvoice();
      } else {
        let series = await this._warehouseService.getOneSerial(this.transferCode, e.transferInventoryDetailInventoryCode);
        for (const i of series.records[0].detail) {
          await this.serialSelected.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription
          });
          await this.serialSelectedTemp.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription
          });
        }
        this.jumpSerial = true;
        await this.saveLineInvoiceSerial();
        this.serialSelectedTemp = [];
      }
      this.jumpSerial = true;
    };

    this.loading = false;
    this.isDisabled = false;
    this.disabled = false;
  }

  public taxToPay: number = 0;
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      (response) => {
        if (response.result) {
          this.company = response.records[0];
          //this.company.companyTaxToPay == 12;
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
          this.companyAddDescription = this.company.companyAddDescription;
          this.companyExentaInvoice = this.company.companyExentaInvoice;

          console.log('company', this.company.companyTaxDocument);

          if(this.company.companyTaxDocument == "4811224" || this.company.companyTaxDocument == "2907097"){
            this.specialFields = true;
          }
          else{
            this.specialFields = false;
          }

        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      (response) => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    // this.dropdownSettings = {
    //   singleSelection: true,
    //   searchPlaceholderText: 'Buscar...',
    //   closeDropDownOnSelection: true,
    //   allowSearchFilter: true,
    //   idField: 'id',
    //   textField: 'itemName',
    // };

    this.dropdownSettingsProduct = {
      singleSelection: true,
      text: "Seleccione...",
      searchPlaceholderText: 'Buscar',
      noDataLabel: "Resultados...",
      enableCheckAll: false,
      enableSearchFilter: true,
      lazyLoading: true,
      idField: 'id',
      textField: 'itemName',
      searchBy: ['id', 'itemName']
    };
  }

  getSelectSettingSerial(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsSerial = [selected] : '';

    this.dropdownSettingsSerial = {
      idField: 'InventorySerialCode',
      textField: 'inventorySerialSerial',
      itemsShowLimit: 3,
      enableCheckAll: false,
      singleSelection: false,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  getInventoryList(company) {
    return this._inventoryService.getAllByStore(company).then(
      response => {
        if (response.result) {
          // console.log(response.records);
          this.inventoryList = response.records;
          // console.log("this.inventoryList ->" + code);
          console.log(this.inventoryList);
          this.inventoryList.forEach((e) => {
            e.itemName = e.itemName.replace(/&apos;/g, '\'');
          });
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  serial = false;
  async onItemSelect(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItem = await this.inventoryList.find(x => x.id == item.id);

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailDescription = item.itemName;    
    this.invoiceDetail.invoiceDetailUnitPrice = _selectedItem.inventoryPrice;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailDescription = item.itemName;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;
    this.Minstock = _selectedItem.inventoryMin;

    if (_selectedItem.inventorySerialFlag == 1) {
      this.loading = true;
      this.serialSelectedTemp = [];
      this.serialList = [];
      const response = await this._inventoryService.getItemSerial(this.itemCode);
      this.serialList = response.records[1];

      this.serial = true;
      this.loading = false;
    } else {
      this.serial = false
    }
  }

  onItemSelectSerial(item: any) {
    this.serialSelected.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });

    this.serialSelectedTemp.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });
  }

  async onItemDeSelectSerial(item: any) {
    this.serialSelected.forEach((e, index) => {
      if (e.serialCode == item.InventorySerialCode)
        this.serialSelected.splice(index, 1);
    });

    this.serialSelectedTemp.forEach((i, indece) => {
      if (i.serialCode == item.InventorySerialCode)
        this.serialSelectedTemp.splice(indece, 1);
    });
  }


  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = 'Q';
      this.invoiceHeader.invoiceCurrency = 'GTQ';
      this.invoiceHeader.invoiceExchangeRate = 0;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = '$';
      this.invoiceHeader.invoiceCurrency = 'USD';
    }
  }

  onChangeMeasurement(event) {
    this.measurementSol = event;

    if (this.measurementSol == 0) {
      this.measurementSolValue = "Yarda";
    } 
    else if (this.measurementSol == 1) {
      this.measurementSolValue = "Metro";
    }
    else{
      this.measurementSolValue = "N/A";
    }
  }

  ///////////////////////FUNCTION CHANGE PRICE///////////////////////
  onChangePrice(event) {
    this.priceType = parseFloat(event);
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  // validateDate($event) {
  //   $event.preventDefault();

  //   var nowDate = this.nowDay.split('-');
  //   var date = this.invoiceHeader.invoiceDate.split('-');

  //   if (
  //     date[0] == nowDate[0] &&
  //     date[1] == nowDate[1] &&
  //     parseFloat(date[2]) <= parseFloat(nowDate[2]) + 5 &&
  //     parseFloat(date[2]) >= parseFloat(nowDate[2]) - 5
  //   ) {
  //   } else {
  //     this.invoiceHeader.invoiceDate = this.nowDay;
  //     this.showNotification(2, 'Fecha fuera de rango');
  //   }
  // }

  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split('-');
    var date = this.invoiceHeader.invoiceDate.split('-');

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (parseFloat(date[2]) <= parseFloat(now[2]) + 5 && parseFloat(date[2]) >= parseFloat(now[2]) - 5) {
          valid = true;
        }
      }
      else if (parseFloat(date[1]) == parseFloat(now[1]) - 1 || parseFloat(date[1]) == 12) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, 'Fecha fuera de rango')
      this.invoiceHeader.invoiceDate = this.nowDay;
    };
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isBilling = false;
  }

  viewBilling() {
    this.isBeginning = false;
    this.isBilling = true;
  }

  // GUARDAR UNA LINEA DE COMPRA
  async saveLineInvoice() {

    if(this.company.companyCode == 24){ //Manejo de decimales ETISA

       //Actualizar?
      let update = false;
      console.log("---invoiceLine---");
      console.log(this.invoiceLine);
      console.log("---invoiceDetail---");
      console.log(this.invoiceDetail);
      console.log("---itemCode---");
      console.log(this.itemCode);
      this.disabled = true;

      await this.invoiceLine.forEach(async (e) => {
        if (e.itemCode == this.itemCode) {
          //Validacion
          let quantity = e.quantity + this.invoiceDetail.invoiceDetailQuantity;

          const response = await this._inventoryService.getPriceByItem(this.itemCode, quantity);
          switch (this.priceType) {
            case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
              break;
            case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
              break;
            case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
              break;
            case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
              break;
          }

          if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
            //Restar Encabezado
            this.invoiceHeader.invoiceTotal -= parseFloat((e.total).toFixed(6));
            this.invoiceHeader.invoiceTax -= parseFloat((e.taxAmount).toFixed(6));

            //Realizar Calculos
            if (parseFloat(e.discount) > 0) {
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((quantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - e.discount).toFixed(6));
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(6));
            } else {
              this.invoiceDetail.invoiceDetailDiscount = e.discount;
              this.invoiceDetail.invoiceDetailSubtotal = quantity * this.invoiceDetail.invoiceDetailUnitPrice;
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(6));
            }

            //Editar Linea
            e.quantity = quantity;
            e.unitPrice = this.invoiceDetail.invoiceDetailUnitPrice;
            e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));
            e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
            e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4));

            //Sumar Encabezado 
            this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(6));
            this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6));
            this.cleanLineInvoice();
          } else {
            this.showNotification(2, 'Cantidad Excede Exitencia');
          }
          update = true;
          this.addAmount = false;
          this.isDisabled = false;
          this.disabled = false;
        }
      });

      //Nuevo Item
      if (this.addAmount === true && update === false) {
        const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceDetail.invoiceDetailQuantity);
        switch (this.priceType) {
          case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        //Realizar Calculos
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
        this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(6));

        //Validacion
        if (this.company.companyExistenceFlag === 1 || this.invoiceDetail.invoiceDetailQuantity <= this.stock) {

          //Guardar Linea
          this.invoiceLine.push({
            // isOil: null,
            // "taxOilName": "PETROLEO",
            // "taxOilCode": 1,
            // "taxOilAmount": 4.70,
            // "taxOilQuantity":"1"
            itemCode: this.itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: this.invoiceDetail.invoiceDetailType,
            quantity: this.invoiceDetail.invoiceDetailQuantity,
            description: this.invoiceDetail.invoiceDetailDescription,
            discount: 0,
            unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(6)),
            price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6)),
            taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6)),
            taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6)),
            total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(6)),
          });
          this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(6));
          this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(6));
          this.cleanLineInvoice();
        } else {
          this.showNotification(2, 'Cantidad Excede Exitencia');
        }
        this.isDisabled = false;
        this.disabled = false;
      }

    }
    else{ //Resto de empresas que no manejan decimales
     
       //Actualizar?
      let update = false;
      console.log("---invoiceLine---");
      console.log(this.invoiceLine);
      console.log("---invoiceDetail---");
      console.log(this.invoiceDetail);
      console.log("---itemCode---");
      console.log(this.itemCode);
      this.disabled = true;

      await this.invoiceLine.forEach(async (e) => {
        if (e.itemCode == this.itemCode) {
          //Validacion
          let quantity = e.quantity + this.invoiceDetail.invoiceDetailQuantity;

          const response = await this._inventoryService.getPriceByItem(this.itemCode, quantity);
          switch (this.priceType) {
            case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
              break;
            case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
              break;
            case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
              break;
            case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
              break;
          }

          if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
            //Restar Encabezado
            this.invoiceHeader.invoiceTotal -= parseFloat((e.total).toFixed(4));
            this.invoiceHeader.invoiceTax -= parseFloat((e.taxAmount).toFixed(4));

            //Realizar Calculos
            if (parseFloat(e.discount) > 0) {
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((quantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(4));
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - e.discount).toFixed(4));
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(4));
            } else {
              this.invoiceDetail.invoiceDetailDiscount = e.discount;
              this.invoiceDetail.invoiceDetailSubtotal = quantity * this.invoiceDetail.invoiceDetailUnitPrice;
              this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(4));
            }

            //Editar Linea
            e.quantity = quantity;
            e.unitPrice = this.invoiceDetail.invoiceDetailUnitPrice;
            e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4));
            e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(4));
            e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4));
            e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4));

            //Sumar Encabezado 
            this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4));
            this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4));
            this.cleanLineInvoice();
          } else {
            this.showNotification(2, 'Cantidad Excede Exitencia');
          }
          update = true;
          this.addAmount = false;
          this.isDisabled = false;
          this.disabled = false;
        }
      });

      //Nuevo Item
      if (this.addAmount === true && update === false) {
        const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceDetail.invoiceDetailQuantity);
        switch (this.priceType) {
          case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        //Realizar Calculos
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(4));
        this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(4));

        //Validacion
        if (this.company.companyExistenceFlag === 1 || this.invoiceDetail.invoiceDetailQuantity <= this.stock) {

          let DifInventario = this.stock - this.invoiceDetail.invoiceDetailQuantity;

          console.log(DifInventario, this.stock, this.invoiceDetail.invoiceDetailQuantity);
          console.log(this.Minstock);

          if(DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }
          //Guardar Linea
          this.invoiceLine.push({
            // isOil: null,
            // "taxOilName": "PETROLEO",
            // "taxOilCode": 1,
            // "taxOilAmount": 4.70,
            // "taxOilQuantity":"1"
            itemCode: this.itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: this.invoiceDetail.invoiceDetailType,
            quantity: this.invoiceDetail.invoiceDetailQuantity,
            description: this.invoiceDetail.invoiceDetailDescription,
            discount: 0,
            unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
            price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4)),
            taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
            taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
            total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)),
          });
          this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4));
          this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4));
          this.cleanLineInvoice();
        } else {
          this.showNotification(2, 'Cantidad Excede Exitencia');
        }
        this.isDisabled = false;
        this.disabled = false;
      }
      
    }   
  }

  // GUARDAR LINEA CON SERIES
  async saveLineInvoiceSerial() {
    this.isDisabled = true;
    this.disabled = true;
    let res_find = await this.invoiceLine.find(x => x.itemCode === this.itemCode);

    if (!res_find) {
      if (this.invoiceDetail.invoiceDetailQuantity == this.serialSelectedTemp.length) {

        if (!this.jumpSerial) {
          this.invoiceDetail.invoiceDetailDescription += ' SERIES: ';
          for await (const e of this.serialSelectedTemp) {
            this.invoiceDetail.invoiceDetailDescription += e.inventorySerialSerial + ', ';
          }
        }

        const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceDetail.invoiceDetailQuantity);
        switch (this.priceType) {
          case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(4));
        this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(4));

        await this.invoiceLine.push({
          //Area de Serie
          serial: this.serialSelectedTemp,
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: this.invoiceDetail.invoiceDetailQuantity,
          description: this.invoiceDetail.invoiceDetailDescription,
          discount: 0,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)),
        });
        this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4));
        this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4));
        this.cleanLineInvoice();

      } else {
        this.showNotification(2, 'Por favor revisa las cantidad de series seleccionadas');
      }
    } else {
      this.showNotification(3, 'Item ya ingresado, por favor eliminalo antes.');
    }
    this.isDisabled = false;
    this.disabled = false;
  }


  // APLICAR DESCUENTO EN TIEMPO REAL
  // ApplyDiscount($event, item, descuento) {
  //   $event.preventDefault();

  //   if(this.company.companyCode == 24){ //Manejo de decimales ETISA

  //     this.invoiceLine.forEach((e) => {
  //       if (e.itemCode == item) {
  //         if (parseFloat(descuento) <= e.price) {
  //           this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(6)); 
  //           e.total = e.price - parseFloat(descuento);
  //           e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(6));
  //           e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(6));
  //           this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(6));
  //           this.cleanLineInvoice();
  //         } else {
  //           this.showNotification(2, "Cantidad No permitida");
  //         }
  //       }
  //     });

  //   }
  //   else{

  //     this.invoiceLine.forEach((e) => {
  //       if (e.itemCode == item) {

  //         let DifInventario = this.stock - e.quantity;

  //         if(DifInventario <= this.Minstock){
  //           this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
  //           //enviar correo
  //         }

  //         if (parseFloat(descuento) <= e.price) {
  //           this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(4)); 
  //           e.total = e.price - parseFloat(descuento);
  //           e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(4));
  //           e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4));
  //           this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(4));
  //           this.cleanLineInvoice();
  //         } else {
  //           this.showNotification(2, "Cantidad No permitida");
  //         }
  //       }
  //     });

  //   }
    
  //   this.ValidarCF();
  // }

  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    if(this.company.companyCode == 24){ //Manejo de decimales ETISA
      
      this.invoiceLine.forEach((e) => {
        if (e.itemCode == item) {
          let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);
  
          if (parseFloat(descuento) <= temporal) {
            this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(6));
            this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(6));
            e.total = parseFloat((temporal - parseFloat(descuento)).toFixed(6));
            e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(6));
            e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(6));
            this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(6));
            this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(6));
          } else {
            this._utilsService.showNotification(2, "Cantidad No permitida");
          }
        }
      });
      
    }
    else{
      this.invoiceLine.forEach((e) => {
        if (e.itemCode == item) {
          let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

          let DifInventario = this.stock - e.quantity;

          if(DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }
  
          if (parseFloat(descuento) <= temporal) {
            this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(4));
            this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(4));
            e.total = parseFloat((temporal - parseFloat(descuento)).toFixed(4));
            e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(4));
            e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4));
            this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(4));
            this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(4));
          } else {
            this._utilsService.showNotification(2, "Cantidad No permitida");
          }
        }
      });
    }    

    this.ValidarCF();
  }

  ApplyQuantity($event, item, cantidad){
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (cantidad > 0) {
          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(4));
          this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(4));
          e.total = parseFloat((temporal).toFixed(4));
          e.taxAmount = parseFloat((parseFloat(e.total) - (parseFloat(e.total) / this.taxToPay)).toFixed(4));
          e.taxableAmount = parseFloat((parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4));
          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(4));
          this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(4));
        } else {
          this._utilsService.showNotification(2, "Cantidad No permitida");
        }
      }
    });

    this.ValidarCF();    
  }

  // ELIMINAR UNA LINEA
  async deleteLine(item) {
    this.isDisabled = true;
    this.disabled = true;
    await this.invoiceLine.forEach(async (e, index) => {
      if (e.itemCode === item) {

        if (e.serial) {
          for (const i of e.serial) {
            let indice = this.serialSelected.findIndex(x => x.serialCode === i.serialCode);
            this.serialSelected.splice(indice, 1);
          };
        }

        this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceHeader.invoiceTax = this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount);
        this.invoiceLine.splice(index, 1);
      }
    })
    this.cleanLineInvoice();
    this.isDisabled = false;
    this.disabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.isDisabled = false;
    this.disabled = false;
    this.addAmount = true;
    this.priceType = 1;
    this.serial = false;
    this.selectedItemsSerial = null;
    this.selectedItems = [];
    this.invoiceDetail = {} as InvoiceDetail;

    this.invoiceExchangeNumber = 1;
    this.invoiceExchangeAmount = 0;
    this.invoiceExchange = [];
    this.infoFees = {} as InfoFees;
    this.invoiceDetail.invoiceDetailQuantity = 1;
  }

  onChangeTypeClient(event) {    
    this.client.clientType = event;

    if(event == 2){
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
    else{
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.disabled = true;
    this.loading = true;

    console.log(this.client.clientType);

    if(this.client.clientType == 2){

      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "info@clever.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(4, "SE EMITIRA FACTURA CON PASAPORTE");
      this.loading = false;

    }
    else{
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService.getOneByCompanyNit(this.companyCode, nit).then(
        response => {
          // console.log(response.records)
          if (response.records.length > 0) {
            this.client = response.records[0];
            this.recurrentClient = true;
            this.insertNewClient = false;
            this.viewBilling();
            this._utilsService.showNotification(1, response.message);
          } else {
            return this._clientService.searchNitSAT(nit, this.client.clientType).then(
              async response => {
                if (response.tipo_respuesta == 0) {
                  //this.client.clientName = response.nombre[0];
                  //console.log(response.nombre[0]);
                  this.client.clientName =  response.nombre[0];
                  this.client.clientStatus = 1;
                  this.client.clientDeleted = 0;
                  this.client.clientPhone = "00000000";
                  this.client.clientEmail = "info@clever.com";
                  this.client.clientAddress = "CIUDAD";

                  this.insertNewClient = true;
                  this.recurrentClient = false;
                  this.viewBilling();
                  this._utilsService.showNotification(4, "Cliente Nuevo");
                } else {
                  this._utilsService.showNotification(3, response.listado_errores[0].error[0].desc_error[0]);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
        this.isDisabled = false;

        this.ValidarCF();
      });
    }
  }

  // CALCULO DE CUOTAS
  async calculateFees() {
    /* Validamos que ingreso la cantidad de abonos y la fecha de vencimiento */
    if (!this.infoFees.amountFees || !this.infoFees.dueDate) {
      this.showNotification(3, 'Campo cantidad de abonos y fecha de vencimiento ¡Obligatorios!');
    } else {
      /* Calculamos el monto por cuota que debe entregar */
      this.amountFees = parseFloat(this.invoiceHeader.invoiceTotal.toFixed(4)) / this.infoFees.amountFees;

      // Inicializamos fecha, arreglo del due y nombres de arreglo
      this.date = new Date(this.invoiceHeader.invoiceDate);
      this.infoFees.due = new Array();

      /* Realizamos un ciclo para ingresar la información al arreglo de las cuotas y fechas */
      for (let i = 0; i < this.infoFees.amountFees; i++) {
        var _date;
        if (i == 0) {
          _date = await this.date.setDate(this.date.getDate() + this.infoFees.dueDate + 1);
        } else {
          _date = await this.date.setDate(this.date.getDate() + this.infoFees.dueDate);
        }

        await this.infoFees.due.push(
          {
            id: i + 1,
            // amount: this.amountFees,
            amount: parseFloat(this.amountFees.toFixed(4)),
            dateFee: _date
          }
        );
      }
      // console.log(this.infoFees);
      this.calculateTrue = true;
    }
  }

  invoiceExchangeNumber;
  invoiceExchangeDate;
  invoiceExchangeAmount;
  public invoiceExchange = [];
  async insertInvoice() {
    if (Object.keys(this.infoFees).length > 0) {
      this.loading = true;
      this.isDisabled = true;
      this.disabled = true;

      //Crear Cliente en la DB
      if (this.insertNewClient) {
        // Resolver problema con DATA BASE
        let nombre;
        nombre = await this.client.clientName.replace(/&/g, '&amp;');
        nombre = await this.client.clientName.replace(/'/g, '&apos;');
        nombre = await this.client.clientName.replace(/"/g, '&quot;');

        this.client.clientName = nombre;
        this.client.clientCredit = 0;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientBlocked = 0;
        this.client.clientInterest = 0;
        this.client.clientDiscount = 0;
        this.client.clientCategory = 2;
        this.client.clientCreditTime = 0;
        this.client.clientCreditAmount = 0;
        this.client.clientCompanyCode = this.companyCode;
        this.client.clientType = this.client.clientType;
        this.client.clientNameFrequent = '-';

        if (this.client.clientTaxDocument && this.client.clientName) {
          const response = await this._clientService.insert(this.client);
          this.client.clientCode = response.records[0].insertId;
        };
      }

      // Pequeño Contribuyente
      let transmitterType = "FCAM";
      let transmitterMembership = "GEN";
      this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';

      this.invoiceHeader.invoiceTax = 0;
      this.invoiceLine.forEach((e) => {
        this.invoiceHeader.invoiceTax += e.taxAmount;
      });

      for await (const e of this.infoFees.due) {
        const d = new Date(e.dateFee)
        var dd = String(d.getDate()).padStart(2, '0');
        var mm = String(d.getMonth() + 1).padStart(2, '0');
        var yyyy = d.getFullYear();

        this.invoiceExchange.push({
          invoiceExchangeSubscriptionNumber: e.id,
          invoiceExchangeSubscriptionDate: yyyy + '-' + mm + '-' + dd,
          invoiceExchangeSubscriptionAmount: parseFloat((e.amount).toFixed(4))
        })
      }

      const hour = await this._utilsService.dateTime('HH:mm:ss');

      let FormaPagoTexto = "";
      if(this.tipoPago == 0){
        FormaPagoTexto = "Efectivo";
      }
      else if(this.tipoPago == 1){
        FormaPagoTexto = "Tarjeta";
      }
      else if(this.tipoPago == 1){
        FormaPagoTexto = "Crédito";
      }

      if(this.company.companyPaymentType == 0){
        await this.PaymentLine.push({
          typePayment: 0,
          NamePaymentType: 'Efectivo',
          MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
        });
      }

      let ADENDASUMMARY = undefined;

      let json;

      if(this.company.companyCode == 24){ //ETISA

        ADENDASUMMARY = {
          Valor1: this.OrdenCompra,
          Valor2: FormaPagoTexto,
          Valor3: this.invoiceHeader.invoiceExchangeRate == undefined ? 0 : this.invoiceHeader.invoiceExchangeRate,
          Valor4: this.invoiceHeader.invoiceCurrency
        };

        json = {
          "store": {
            "storeCode": this.storeCode
          },
          "user": {
            "userCode": this.userCode
          },
          "transmitter": {
            "transmitterMembership": transmitterMembership,
            "transmitterType": transmitterType,
            "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
            "transmitterEmail": this.company.companyEmail,
            "transmitterTaxDocument": this.company.companyTaxDocument,
            "transmitterComercialName": this.store.storeName,
            "transmitterName": this.company.companyName,
            "transmitterAddress": this.store.storeAddress,
            "transmitterZipCode": this.store.storeZipCode,
            "transmitterMunicipality": this.store.storeMunicipality,
            "transmitterDepartment": this.store.storeDepartment,
            "transmitterCountry": 'GT',
            "transmitterUser": this.company.companyPrefix,
            "transmitterPrefix": this.company.companyPrefix,
            "transmitterKey": this.company.companyKey,
            "transmitterToken": this.company.companyToken,
            "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
            "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
          },
          "receiver": {
            "receiverAddress": this.client.clientAddress,
            "receiverCode": this.client.clientCode,
            "receiverDeleted": 0,
            "receiverEmail": this.client.clientEmail,
            "receiverName": this.client.clientName,
            "receiverStatus": 1,
            "receiverTaxDocument": this.client.clientTaxDocument,
            "receiverTypeTaxDocument": this.client.clientType,
            "receiverZipCode": 0,
            "receiverCountry": "GT"
          },
          "invoice": {
            "invoiceCode": this.invoiceHeader.invoiceCode,
            "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial
          },
          "invoiceExchange": {
            "invoiceExchangeCode": 1, 
            "invoiceExchangeDate": this.invoiceHeader.invoiceDate + ' ' + hour,
            "invoiceExchangeInternSerial": this.invoiceHeader.invoiceInternSerial,
            "invoiceExchangeCurrency": this.invoiceHeader.invoiceCurrency,
            "invoiceExchangeChangeRate": this.invoiceHeader.invoiceExchangeRate,
            "invoiceExchangeTaxShortName": "IVA",
            "invoiceExchangeTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(4)),
            "invoiceExchangeTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4)),
            "invoiceExchangeComplementId": "Cambiaria",
            "invoiceExchangeComplementName": "Cambiaria",
            "invoiceExchangepaymentType": this.tipoPago,
          },
          "phrase": this.company.phrase,
          "detail": this.invoiceLine,
          "paymentType": this.PaymentLine,
          "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
          "serial": this.serialSelected,
          "detailsComplement": this.invoiceExchange,
          "addendumX": ADENDASUMMARY,
        };

        console.log(json);

      }
      else{ //Resto Clientes

        if(this.specialFields){

          ADENDASUMMARY = {
            RefIntern: "0",
            codeSol: this.codeSol,
            salePersonSol:this.salePersonSol,
            measurementSol: this.measurementSol
          };
  
        }
        
        if(this.company.companyTaxDocument == '4811224' || this.company.companyTaxDocument == '2907097'){
          console.log('this.ExentaInvoice', this.ExentaInvoice);
          if(this.ExentaInvoice){
            console.log('this.company.phrase', this.company.phrase);            
            this.company.phrase.push({phraseStage: '11', phrase: '4'});
          }          
        }

        json = {
          "store": {
            "storeCode": this.storeCode
          },
          "user": {
            "userCode": this.userCode
          },
          "transmitter": {
            "transmitterMembership": transmitterMembership,
            "transmitterType": transmitterType,
            "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
            "transmitterEmail": this.company.companyEmail,
            "transmitterTaxDocument": this.company.companyTaxDocument,
            "transmitterComercialName": this.store.storeName,
            "transmitterName": this.company.companyName,
            "transmitterAddress": this.store.storeAddress,
            "transmitterZipCode": this.store.storeZipCode,
            "transmitterMunicipality": this.store.storeMunicipality,
            "transmitterDepartment": this.store.storeDepartment,
            "transmitterCountry": 'GT',
            "transmitterUser": this.company.companyPrefix,
            "transmitterPrefix": this.company.companyPrefix,
            "transmitterKey": this.company.companyKey,
            "transmitterToken": this.company.companyToken,
            "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
            "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
          },
          "receiver": {
            "receiverAddress": this.client.clientAddress,
            "receiverCode": this.client.clientCode,
            "receiverDeleted": 0,
            "receiverEmail": this.client.clientEmail,
            "receiverName": this.client.clientName,
            "receiverStatus": 1,
            "receiverTaxDocument": this.client.clientTaxDocument,
            "receiverTypeTaxDocument": this.client.clientType,
            "receiverZipCode": 0,
            "receiverCountry": "GT"
          },
          "invoice": {
            "invoiceCode": this.invoiceHeader.invoiceCode,
            "invoiceInternSerial": this.invoiceHeader.invoiceInternSerial
          },
          "invoiceExchange": {
            "invoiceExchangeCode": 1, 
            "invoiceExchangeDate": this.invoiceHeader.invoiceDate + ' ' + hour,
            "invoiceExchangeInternSerial": this.invoiceHeader.invoiceInternSerial,
            "invoiceExchangeCurrency": this.invoiceHeader.invoiceCurrency,
            "invoiceExchangeChangeRate": this.invoiceHeader.invoiceExchangeRate,
            "invoiceExchangeTaxShortName": "IVA",
            "invoiceExchangeTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(4)),
            "invoiceExchangeTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4)),
            "invoiceExchangeComplementId": "Cambiaria",
            "invoiceExchangeComplementName": "Cambiaria",
            "invoiceExchangepaymentType": this.tipoPago,
          },
          "phrase": this.company.phrase,
          "detail": this.invoiceLine,
          "paymentType": this.PaymentLine,
          "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
          "serial": this.serialSelected,
          "detailsComplement": this.invoiceExchange,
          "addendum": ADENDASUMMARY,
          "exenta": this.ExentaInvoice,
        };

        console.log(json);

      }      

      // Prueba de sistema por soporte Clever
      // console.log(json);
      // this.loading = false;
      // this.isDisabled = false;
      // Insertar Venta
      if (this.company.companyCertifier == 0) {
        this._invoiceService.insertInvoiceExchange(json).then(
          async (response) => {
            if (response.result) {
              let uuid = response.invoiceExchangeAuthorization;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
          this.disabled = false;
        });
      }
      else if (this.company.companyCertifier == 1) {
        this._invoiceService.insertInvoiceExchange(json).then(
          async (response) => {
            if (response.result) {
              var pdfResult = response.invoiceExchangePdf;

              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
          this.disabled = false;
        });
      }

      this.insertNewClient = false;
    } else {
      this.showNotification(2, 'Falto llenar Campos')
    }

    if(this.companyCode == 22){
      this.isNailCenter = true;
      this.getAll(this.storeCode);
    }
  }

  // GET ALL INVENTORY
  getAll(storeCode) {
    this.loading = true;
    // console.log(storeCode, "este es el store code para nail center")
    return this._inventoryService.getAllByStore(storeCode).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          this.temp = this.inventoryList;
          console.log(this.inventoryList);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  // GET ONE INVENTORY
  viewFunction($event) {
    $event.preventDefault();
    let series;
    this.activeRow.inventorySerialFlag == 1 ? series = 'SI' : series = 'NO';

    swal.fire({
      title: "Item:",
      imageUrl: `https://storage.googleapis.com/nail-center/Imagenes/${this.activeRow.inventoryBusinessCode}.jfif`,
      imageHeight: 300,
      html:
        '<div class="text-left">' +
        "<b>Código: </b>" +
        this.activeRow.inventoryBusinessCode +
        "<br>" +
        "<b>Nombre: </b>" +
        this.activeRow.inventoryName +
        "<br>" +
        "<b>Series: </b>" +
        series +
        "<hr>" +
        "<b>Precio Venta: </b>" +
        this.activeRow.inventoryPrice +
        "<br>" +
        "<b>Precio Minimo de Venta: </b>" +
        this.activeRow.inventoryMinPrice +
        "<br>" +
        "<b>Precio con Descuento: </b>" +
        this.activeRow.inventoryPriceDiscount +
        "<br>" +
        "<b>Precio de Promoción: </b>" +
        this.activeRow.inventorySalePrice +
        "<hr>" +
        "<b>Stock: </b>" +
        this.activeRow.inventoryQuantity +
        "<br>" +
        "<b>Stock Mínimo: </b>" +
        this.activeRow.inventoryMin +
        "<br>" +
        "<b>Stock Máximo: </b>" +
        this.activeRow.inventoryMax +
        "<hr>" +
        "<b>Cód. de Orden: </b>" +
        this.activeRow.inventoryPurchaseDetail +
        "<br>" +
        "<b>Descripción: </b>" +
        this.activeRow.inventoryDescription +
        "<br></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false,
    });
  }
  
  onActivate(event) {
    this.activeRow = event.row;
  }

  // Limpiar Campos
  cleanModel() {
    this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = 'Q';
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = 'GTQ';
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';
    this.invoiceDetail.invoiceDetailQuantity = 1;
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.invoiceExchangeDate = this.nowDay;
    this.invoiceExchangeNumber = 1;
    this.invoiceExchangeAmount = 0;
    this.invoiceExchange = [];
    this.infoFees = {} as InfoFees;
    this.client.clientType = 0;
    this.PaymentLine = [];
    this.MontoTotalPayment = 0;
    this.measurementSolValue = "";
    // this.specialFields = false;
    this.refInternSol = "0";
    this.codeSol = "";
    this.salePersonSol = "";
    this.measurementSol = 0;

    this.addAmount = true;
    this.viewBeginning();
  }

  viewCF() {
    this.isBeginning = true;
    this.isBilling = true;
    this.disabled = true;
  }

  ValidarCF() {
    /*J11 - Validar CF*/
    console.log(this.invoiceHeader.invoiceTotal);
    console.log(this.client.clientTaxDocument);

    if(this.invoiceHeader.invoiceTotal >= 2500){
      if(this.client.clientTaxDocument == "CF"){
        this._utilsService.showNotification(5, "No puede facturar como CF, el monto es mayor a 2500");
        this.viewCF();
      }
      else{
        this.disabled = false;
      }
    }
  }

  //Mostrar Notificacion
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-success alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 4:
        this.toastr.info(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-info alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' +
          message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-danger alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 5:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 15000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }
}
