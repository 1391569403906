import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InvoiceHeader } from '../models/invoiceHeader';

@Injectable()
export class InvoiceService {
    public url: string;
    public urlFactura: string;
    public urlFEL: string;

    constructor(
        private _http: HttpClient,        
    ) {
        this.url = environment.apiUrl;
        this.urlFEL = environment.apiFel;
    }

    ///////////////////////INSERT///////////////////////
    // INSERT RECURRENT
    insertRecurrentBilling(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-job', params, { headers }).toPromise();
    }

    // DELETED RECURRENT INVOICE
    updateRecurrentBilling(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-job', params, { headers: headers }).toPromise();
    }

    //GET RECURRENT INVOICE
    recurrentInvoice(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'imerbackend-job/company/' + code, { headers: headers }).toPromise();
    }

    // INSERT INFILE / MEGAPRINT
    insertBilling(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoice', params, { headers }).toPromise();
    }

    // INSERT SPECIAL INFILE / MEGAPRINT
    insertSpecialBilling(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceSpecial', params, { headers }).toPromise();
    }

    // INSERT FUEL MEGAPRINT
    insertBillingMegaprintFuel(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoice/fuel', params, { headers }).toPromise();
    }

    // INSERT CREDIT NOTE
    insertCreditNote(credit): Promise<any> {
        const params = JSON.stringify(credit);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-creditNote', params, { headers }).toPromise();
    }                                    

    // INSERT DEBIT NOTE
    insertDebitNote(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-debitNote', params, { headers }).toPromise();
    }

    // INSERT EXPORT
    insertInvoiceExport(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceExport', params, { headers }).toPromise();
    }

    // INSERT CHANGE
    insertInvoiceExchange(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceExchange', params, { headers }).toPromise();
    }

    // INSERT INFILE / MEGAPRINT
    insertBillingReceipt(invoice): Promise<any> {
        const params = JSON.stringify(invoice);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-receipt', params, { headers }).toPromise();
    }

    ///////////////////////UPDATE///////////////////////
    updateInvoiceDuesQuantity(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-invoice', params, { headers: headers }).toPromise();
    }

    updateInvoiceSpecialDuesQuantity(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-invoiceSpecial', params, { headers: headers }).toPromise();
    }

    updateInvoiceExchangeQuantity(data): Promise<any> {
        const params = JSON.stringify(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.url + 'imerbackend-invoiceExchange', params, { headers: headers }).toPromise();
    }

    ///////////////////////GET///////////////////////
    //GET ONE INVOICE BY MEGAPRINT
    getOneMegaprint(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'imerbackend-invoice/one/' + code + '/megaprint', { headers: headers }).toPromise();
    }

    //GET ONE INVOICE SPECIAL BY MEGAPRINT
    getOneSpecialMegaprint(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'imerbackend-invoiceSpecial/one/' + code + '/megaprint', { headers: headers }).toPromise();
    }

    getOneMegaprintExchange(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');        
        return this._http.get(this.url + 'imerbackend-invoiceExchange/one/' + code + '/megaprint', { headers: headers }).toPromise();
    }
    
    getPDFMegaprintExchange(UUID): Promise<any> {
        const params = JSON.stringify(UUID);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');    
        //return this._http.get(this.url + 'imerbackend-invoice/megaprint', { headers: headers }).toPromise();
        return this._http.post(this.urlFEL + 'imfel-invoice/megaprint/pdf', params, { headers }).toPromise();
    }

    //GET ONE INVOICE FOR CREDIT/DEBIT NOTE
    getOneAsArray(code): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + '/imerbackend-invoice/one/' + code, { headers: headers }).toPromise();
    } 

    getAllCreditNote(json): Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-creditNote/report/store', params, { headers: headers }).toPromise();
    }

    getAllDebitNote(json): Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-debitNote/report/store', params, { headers: headers }).toPromise();
    } 

    ///////////////////////ANULMENT/CANCEL///////////////////////
    // GET HEADERS INVOICE LIST BY COMPANY 
    getAllByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoice/report/company', params, { headers: headers }).toPromise();
    }

    // GET HEADERS INVOICE SPECIAL LIST BY COMPANY 
    getAllSpecialByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceSpecial/report/company', params, { headers: headers }).toPromise();
    }

    // GET HEADERS + RETENTION INVOICE LIST BY COMPANY 
    getAllByCompanyRetention(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoice/report/company/extra', params, { headers: headers }).toPromise();
    }

    // GET HEADERS EXCHANGE BILL LIST BY COMPANY 
    getAllExchangeByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceExchange/report/company', params, { headers: headers }).toPromise();
    }

    getAllByCompanyReceipt(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-receipt/report/company', params, { headers: headers }).toPromise();
    }

    // INSERT ANULMENT
    annular(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoice/cancel/', params, { headers: headers }).toPromise();
    }

    // INSERT ANULMENT EXCHANGE BILL
    annularExchange(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-invoiceExchange/cancel', params, { headers: headers }).toPromise();
    }

    annularReceipt(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-receipt/cancel/', params, { headers: headers }).toPromise();
    }

    ///////////////////////REPORT///////////////////////
    // BY STORE
    getReportByCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-sale/report/company', params, { headers: headers }).toPromise();
    }

    // BY STORE
    getReportByCompanyDetail(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-sale/report/company/detail', params, { headers: headers }).toPromise();
    }

    // BY COMPANY
    getReportAllCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-sale/report/company/header/company', params, { headers: headers }).toPromise();
    }

    // BY COMPANY RECEIPT
    getReportReceiptAllCompany(json):Promise<any> {
        const params = JSON.stringify(json);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + 'imerbackend-sale/reportreceipt/company/header/company', params, { headers: headers }).toPromise();
    }

    // GET XML CERTIFICATE    
    xmlInvoiceExchange(invoiceCode: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-invoiceExchange/xml/one/${invoiceCode}`, { headers: headers }).toPromise();
    }
    
    xmlCreditNote(creditNoteCode: string): Promise<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.url + `imerbackend-creditNote/xml/one/${creditNoteCode}`, { headers: headers }).toPromise();
    }

    changeStatusPresale(file_name, status, carpeta): Promise<any> {
        let body = JSON.stringify({
            bucket_name: "nail-center",
            folder_name: `${carpeta}/${file_name}`,          
            status : status
        });

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
            .post(
                "https://us-central1-clever-375004.cloudfunctions.net/change_status",
                body,
                { headers }
            )
            .toPromise();
    }

    updatePresale(file_name, newGuid, invoice, pdfHmtl, correlativo, carpeta): Promise<any> {
        let body = JSON.stringify({
            bucket_name: "nail-center",
            carpeta: carpeta,
            filename: file_name,
            content: btoa(pdfHmtl),
            correlativo,
            content_type: "text/html",
            invoice,
            newGuid,
        });

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
            .post(
                "https://us-central1-clever-375004.cloudfunctions.net/update_File",
                body,
                { headers }
            )
            .toPromise();
    }

    sendEmail_Presale(data_to_send): Promise<any> {        
        let body = JSON.stringify({
            reciever_mail: data_to_send.email,
            presale_number: data_to_send.presale_number.toString(),
            html_content: data_to_send.html_content,
        });

        console.log(body)

        let headers = new HttpHeaders().set("Content-Type", "application/json");
        return this._http
            .post(
                "https://us-central1-clever-375004.cloudfunctions.net/send_Email",
                body,
                { headers }
            )
            .toPromise();
    }

}
