<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-5">
        <div>
            <a class="a-home" routerLink="/home">Home/</a>
        </div>
        <div>
            <a class="text-info" routerLink="/billing">Factura Cambiaria de Inventario</a>
        </div>
    </div>
</div>
<div class="main-content">

    <!-- BUSCAR NIT -->
    <div class="row justify-content-center" *ngIf="isBeginning">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <label>Tipo de Identificación</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <select class="form-control form-control-sm" [ngModel]="client.clientType"
                                (ngModelChange)="onChangeTypeClient($event)">
                                <option [value]="0">NIT</option>
                                <option [value]="1">CUI</option>
                                <option [value]="2">Pasaporte</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label>No. Identificación</label>
                        </div>
                    </div>
                    <div class="row">                        
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument"
                                    (keyup.enter)="buscarNITLocal($event, client.clientTaxDocument)"
                                    placeholder="*Escribe un NIT/CUI/Pasaporte sin espacios o guiones" minlength="8" />
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-9">
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientName" [toggleable]="true" [disabled]="disabledEXT"
                                    placeholder="*Escribe el nombre completo del receptor" minlength="8" />
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-3">                        
                            <button class="btn btn-info btn-sale"
                                (click)="buscarNITLocal($event, client.clientTaxDocument)" [disabled]="isDisabled"
                                style="width: 100%;">Buscar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button class="btn btn-info btn-sale" style="width: 100%;"
                                (click)="buscarNITLocal($event, 'CF')" [disabled]="isDisabled">Consumidor Final</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN BUSCAR NIT -->

    <!-- MODULO CLIENTE -->
    <div class="row justify-content-center" *ngIf="isBilling">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-1 ma-g">
                            <div class="row">
                                <div class="col-md-12">
                                    <a class="btn btn-round btn-info btn-icon btn-back remove"
                                        (click)="viewBeginning()">
                                        <i class="now-ui-icons arrows-1_minimal-left"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-11 ma-g">
                            <div class="row">
                                <div class="col-md-2 ma-g">
                                    <label>Nit</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientTaxDocument" [disabled]="recurrentClient"
                                            disabled />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>Nombre</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientName"/>
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>E-mail</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g" [(ngModel)]="client.clientEmail" />
                                    </div>
                                </div>
                                <div class="col-md-4 ma-g">
                                    <label>Dirección</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control pa-g"
                                            [(ngModel)]="client.clientAddress" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO CLIENTE -->

    <div class="row justify-content-center" *ngIf="isBilling && specialFields">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12"><strong>Datos especiales</strong></div>
                        <!-- <div class="col-md-3 ma-g">
                            <label>Ref. Interna</label>
                            <div class="form-group">
                                <input type="text" class="form-control pa-g" [(ngModel)]="refInternSol" />
                            </div>
                        </div> -->
                        <div class="col-md-3 ma-g">
                            <label>Código</label>
                            <div class="form-group">
                                <input type="text" class="form-control pa-g" [(ngModel)]="codeSol" />
                            </div>
                        </div>
                        <div class="col-md-3 ma-g">
                            <label>Vendedor</label>
                            <div class="form-group">
                                <input type="text" class="form-control pa-g"
                                    [(ngModel)]="salePersonSol" />
                            </div>
                        </div>
                        <div class="col-md-3 ma-g">
                            <label>Unidad Medida</label>
                            <select class="form-control form-control-sm" [ngModel]="measurementSol"
                                (ngModelChange)="onChangeMeasurement($event)">
                                <option [value]="0">--SELECCIONE--</option>
                                <option [value]="1">Yardas</option>
                                <option [value]="2">Pulgadas</option>
                                <option [value]="3">Gruesas</option>
                                <option [value]="4">Unidades</option>
                                <option [value]="5">Kilos</option>
                                <option [value]="6">Libras</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODULO FORMAS DE PAGO -->
    <div class="row justify-content-center" *ngIf="isBilling && isMethodPay">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <!-- Formas de pago -->
                    <div class="col-sm-12"><b>Formas de Pago</b></div>
                    <div class="row">
                        <div class="col-md-5">
                            <label>Forma de Pago</label>
                            <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                [settings]="dropdownSettingsPayment" [data]="PaymentList" [(ngModel)]="selectedItemsPayment"
                                (onSelect)="onItemSelectPaymet($event)" [disabled]='isEdit'>
                            </ng-multiselect-dropdown>                            
                        </div>   
                        <div class="col-md-2">
                            <label>Monto {{moneda}}</label>
                            <div class="form-group">
                                <input type="number" class="form-control"
                                    [(ngModel)]='MontoPayment' placeholder="Monto" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>No. Autorización</label>
                            <div class="form-group">
                                <input type="text" class="form-control"
                                    [(ngModel)]='Autorizacion' placeholder="Monto" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label> . </label>
                            <button type="submit" class="btn btn-info btn-sale" (click)="addPaymetDetail()" [toggleable]="true" [disabled]="disabledPay" >Agregar</button>
                        </div>                       
                    </div>
                    <br>
                    <!-- Detalle de Venta -->
                    <div class="col-md-8">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td class="text-center">No.</td>
                                        <td class="text-left">Forma de Pago</td>
                                        <td class="text-center">Monto {{moneda}}</td>
                                        <td class="text-center">Autorización</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of PaymentLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>                                        
                                        <td>{{e.NamePaymentType}}</td>                                        
                                        <td class="text-center">{{e.MontoPayment | number:'1.2-2'}}</td>
                                        <td>{{e.Autorizacion}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deletePaymetDetail(e.typePayment)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;">
                            Total Pagado <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{MontoTotalPayment | number:'1.2-2'}}
                        </div>                        
                    </div>
                    <!-- Fin formas de pago -->                    
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO FORMAS DE PAGO -->

    <!-- PRODUCTOS -->
    <div class="row justify-content-center" *ngIf="isBilling">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Fecha</label>
                            <input type="date" class="form-control form-control-sm"
                                [(ngModel)]="invoiceHeader.invoiceDate" (change)="validateDate($event)" />
                        </div>
                        <div class="col-md-2">
                            <label>Serie</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceInternSerial"
                                (ngModelChange)="onChangeInternSerial($event)">
                                <option value=A>A</option>
                                <option value=B>B</option>
                                <option value=C>C</option>
                                <option value=D>D</option>
                                <option value=E>E</option>
                                <option value=F>F</option>
                                <option value=G>G</option>
                                <option value=H>H</option>
                                <option value=I>I</option>
                                <option value=J>J</option>
                                <option value=K>K</option>
                                <option value=L>L</option>
                                <option value=M>M</option>
                                <option value=N>N</option>
                                <option value=L>L</option>
                                <option value=O>O</option>
                                <option value=P>P</option>
                                <option value=Q>Q</option>
                                <option value=R>R</option>
                                <option value=S>S</option>
                                <option value=T>T</option>
                                <option value=U>U</option>
                                <option value=V>V</option>
                                <option value=W>W</option>
                                <option value=X>X</option>
                                <option value=Y>Y</option>
                                <option value=Z>Z</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Moneda</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.invoiceCoin"
                                (ngModelChange)="onChange($event)">
                                <option [value]="0">Q - Quetzal</option>
                                <option [value]="1">$ - Dolar</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label>Tasa de Cambio</label>
                            <input type="number" class="form-control form-control-sm" style="background: #FFFFFF;"
                                [(ngModel)]="invoiceHeader.invoiceExchangeRate" [disabled]="moneda == 'Q'" />
                        </div>
                        <div class="col-md-2">
                            <label>Forma de Pago</label>
                            <select class="form-control form-control-sm" [ngModel]="invoiceHeader.paymentType"
                                (ngModelChange)="onChangepaymentType($event)">
                                <option [value]="0">Efectivo</option>
                                <option [value]="1">Tarjeta</option>
                                <option [value]="2">Crédito</option>
                            </select>
                        </div>
                        <div class="col-md-2" style="text-align: end;">
                            <button class="btn btn-success btn-sale" (click)="insertInvoice()" [disabled]="isDisabledInvoice">
                                ✔ Realizar Venta
                            </button>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="companyExentaInvoice == 1">
                        <label>Factura Exenta</label>
                        <div class="form-group">
                            <bSwitch [switch-on-color]="'success'" [(ngModel)]="ExentaInvoice" switch-on-text="<i class='now-ui-icons ui-1_check'></i>" switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>"></bSwitch>
                        </div>
                    </div>
                    <div class="row" *ngIf="companyCode == 24">
                        <div class="col-md-2">
                            <label>No. Orden de Compra</label>
                            <input type="number" class="form-control form-control-sm" style="background: #FFFFFF;"
                                [(ngModel)]="OrdenCompra" />
                        </div>
                    </div>
                    <br>
                    <!-- <div class="row">
                        <div class="col-sm-12 col-md-10">
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <div class="form-group">
                                        <input type="text" (input)="CargarSKU()" [(ngModel)]="SKUx" id="SKUsearch" class="form-control" placeholder="SKU, Codigo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12 col-md-10">
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <!-- <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                        [settings]="dropdownSettings" [data]="inventoryList" [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)">
                                    </ng-multiselect-dropdown> -->
                                    <!-- [disabled]='isEdit'-->
                                    <angular2-multiselect [data]="inventoryList" [(ngModel)]="selectedItems"
                                        [settings]="dropdownSettingsProduct" (onSelect)="onItemSelect($event)" [disabled]='isEdit'>
                                    </angular2-multiselect>
                                </div>
                                <div class="col-sm-12 col-md-2 form-group">
                                    <select class="form-control" [ngModel]="priceType"
                                        (ngModelChange)="onChangePrice($event)">
                                        <option value=1>Normal</option>
                                        <option value=2>Descuento</option>
                                        <option value=3>Oferta</option>
                                        <option value=4>Minimo</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="form-group">
                                        <input type="number" class="form-control"
                                            [(ngModel)]='invoiceDetail.invoiceDetailQuantity' placeholder="Cantidad" />
                                    </div>
                                </div>
                                <div class="col-sm-12" *ngIf="serial">
                                    <ng-multiselect-dropdown [placeholder]="'Seleccione...'"
                                        [settings]="dropdownSettingsSerial" [data]="serialList"
                                        [(ngModel)]="selectedItemsSerial" (onSelect)="onItemSelectSerial($event)"
                                        (onDeSelect)="onItemDeSelectSerial($event)">
                                    </ng-multiselect-dropdown>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLineInvoiceSerial()" [toggleable]="true" [disabled]="isDisabled"
                                *ngIf="serial">Agregar</button>
                            <button type="submit" class="btn btn-info btn-sale" (click)="saveLineInvoice()" [toggleable]="true" [disabled]="isDisabled"
                                *ngIf="!serial">Agregar</button>
                        </div>
                    </div>
                    <!-- Agregar descripciones al detalle factura -->
                    <div class="row" *ngIf="companyAddDescription == 1">
                        <div class="col-md-6">
                            <div class="row">
                                <div>
                                    <input type="hidden" [(ngModel)]="itemCode" />
                                </div>
                                <!-- <div class="col-md-4 pa-star">
                                    <div class="form-group">
                                        <select class="form-control pa" [(ngModel)]="invoiceDetail.invoiceDetailType">
                                            <option value="B">Bien</option>
                                            <option value="S">Servicio</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-12 pa-mar">
                                    <div class="form-group">
                                        <!-- <input type="text" class="form-control pa"
                                            [(ngModel)]="invoiceDetail.invoiceDetailDescription"
                                            placeholder="Descripción" /> -->

                                            <textarea class="form-control" [(ngModel)]="invoiceDetail.invoiceDetailDescription" rows="3" placeholder="Descripcion"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-3 pa-mar">
                                    <div class="form-group">
                                        <input type="number" class="form-control pa"
                                            [(ngModel)]="invoiceDetail.invoiceDetailQuantity" (blur)="calculateTax()"
                                            placeholder="Cantidad" />
                                    </div>
                                </div>
                                <div class="col-md-3 pa-mar">
                                    <div class="form-group">
                                        <input type="number" class="form-control pa"
                                            [(ngModel)]="invoiceDetail.invoiceDetailUnitPrice" (blur)="calculateTax()"
                                            placeholder="Precio" />
                                    </div>
                                </div>
                                <div class="col-md-3 pa-mar">
                                    <div class="form-group">
                                        <input type="number" class="form-control pa"
                                            [(ngModel)]="invoiceDetail.invoiceDetailDiscount" placeholder="Descuento"
                                            (blur)="calculateTax()" />
                                    </div>
                                </div>
                                <div class="col-md-3 pa-mar">
                                    <div class="form-group">
                                        <input type="number" class="form-control pa"
                                            [(ngModel)]="invoiceDetail.invoiceDetailTax" placeholder="Impuesto"
                                            disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="row">
                                <div class="col-md-12 pa-end">
                                    <button type="submit" class="btn-min btn btn-info btn-sale" [toggleable]="true" [disabled]="isDisabled"
                                        style="padding: 0px 0px 0px 0px !important;" (click)="saveLineInvoiceDesc()">
                                        ✔ Agregar Desc.</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin Agregar descripciones al detalle factura -->
                    <!-- Detalle de Venta -->
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr style="font-weight: bold;">
                                        <td class="text-center">No.</td>
                                        <td class="text-center">B/S</td>
                                        <td class="text-center">Cantidad</td>
                                        <td class="text-center">Descripción</td>
                                        <td class="text-center">P/U con IVA {{moneda}}</td>
                                        <td class="text-center">Descuento</td>
                                        <td class="text-center">Total {{moneda}}</td>
                                        <td class="text-center">Impuesto {{moneda}}</td>
                                        <td class="text-center">Opción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of invoiceLine, let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{e.isService}}</td>
                                        <!-- <td class="text-center">{{e.quantity | number:'1.2-2'}}</td> -->
                                        <td class="text-center">
                                            <input type="number" class="form-control" [(ngModel)]="e.quantity"
                                                (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                                                style="max-width: 90px; margin: 0px auto;" />
                                        </td>
                                        <!-- <td>{{e.description}}</td> -->
                                        <td>
                                            <textarea class="form-control" [(ngModel)]="e.description"
                                                style="max-width: 250px; margin: 0px auto;" rows="4"></textarea>
                                        </td>
                                        <td class="text-right" *ngIf="companyCode == 24">{{e.unitPrice + e.taxOilUP | number:'1.4-4'}}</td>
                                        <td class="text-right" *ngIf="companyCode != 24">{{e.unitPrice + e.taxOilUP | number:'1.2-2'}}</td>
                                        <td style="text-align: right;">
                                            <input type="number" class="form-control" [(ngModel)]="e.discount"
                                                (blur)="ApplyDiscount($event, e.itemCode, e.discount)"
                                                style="max-width: 90px; margin: 0px auto;" />
                                        </td>
                                        <td class="text-center"  *ngIf="companyCode == 24">{{e.total | number:'1.2-2'}}</td>
                                        <td class="text-center"  *ngIf="companyCode != 24">{{e.total | number:'1.2-2'}}</td>
                                        <td class="text-center" style="color: #b7b7b7;" *ngIf="companyCode == 24">{{e.taxAmount | number:'1.2-2'}}</td>
                                        <td class="text-center" style="color: #b7b7b7;" *ngIf="companyCode != 24">{{e.taxAmount | number:'1.2-2'}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger btn-sm" (click)="deleteLine(e.itemCode)"
                                                [disabled]="isDisabled" ngbTooltip="Eliminar" placement="left">
                                                <i class="now-ui-icons ui-1_simple-remove"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="companyCode == 24">
                            <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                                Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                            Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                        </div>
                        <div class="col-md-12" style="text-align: end; font-weight: bold; font-size: 25px; padding-right: 35px;" *ngIf="companyCode != 24">
                            <small *ngIf="invoiceHeader.invoiceIDP > 0" style="font-weight: bold; font-size: 20px;">IDP
                                Q{{invoiceHeader.invoiceIDP | number:'1.2-2'}} &nbsp;&nbsp;</small>
                            Total <small style="font-weight: bold; font-size: 20px;">{{moneda}}</small>
                            {{invoiceHeader.invoiceTotal | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin Productos -->

    <!-- MODULO ABONOS -->
    <div class="row justify-content-center" *ngIf="isBilling">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <!-- CUOTAS -->
                    <div class="row">
                        <div class="col-md-12 ma-g">
                            <div class="row">
                                <div class="col-md-3 ma-g">
                                    <label>Cantidad de abonos</label>
                                    <div class="form-group">
                                        <input type="number" class="form-control pa-g"
                                            [(ngModel)]="infoFees.amountFees" />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label>Frecuencia de vencimiento</label>
                                    <div class="form-group">
                                        <input type="number" class="form-control pa-g" [(ngModel)]="infoFees.dueDate" />
                                    </div>
                                </div>
                                <div class="col-md-3 ma-g">
                                    <label style="color: transparent;">Boton agregar</label>
                                    <button type="submit" class="btn-min btn btn-info btn-sale"
                                        style="padding: 0px 0px 0px 0px !important;" (click)="calculateFees()">
                                        Agregar </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="text-align: end;" *ngIf="calculateTrue">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class=" text-primary">
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Monto</th>
                                                    <th>Vencimiento</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let due of infoFees.due">
                                                <tr>
                                                    <td>{{due.id}}</td>
                                                    <td>{{due.amount | number:'1.2-4'}}</td>
                                                    <td>{{ due.dateFee | date:'dd/MM/yyyy' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN MODULO CLIENTE -->

    <!-- TABLE ALL -->
    <div class="row" *ngIf="isBilling && isNailCenter">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-title">
                                <b>{{storeName}}</b>
                                <br>Listado de Ítems.
                            </h4>
                            <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- [columnMode]="'ColumnMode.flex | .force | .standard'" [flexGrow]="3" [width]="300" [minWidth]="200" [maxWidth]="200"-->
                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [colWidth]="'auto'"
                            [limit]="entries != -1 ? entries:undefined" [scrollbarH]="true" [rows]="temp"
                            (activate)="onActivate($event)">
                            <ngx-datatable-column name="codigo" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="nombre" [minWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="venta" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column name="stock" [maxWidth]="200"></ngx-datatable-column>
                            <ngx-datatable-column [minWidth]="200" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value"
                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                    let-onCheckboxChangeFn="onCheckboxChangeFn" let-row="row">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like"
                                        (click)="viewFunction($event)" ngbTooltip="Ver"><i class="fas fa-eye"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END TABLE -->
</div>