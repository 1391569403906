import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '../models/store';
import { environment } from 'src/environments/environment';


@Injectable()
export class StoreService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // GET ALL STORE
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "imerbackend-store", { headers: headers })
      .toPromise();
  }
  // GET ALL STORE BY COMPANY
  getAllByCompany(company): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "imerbackend-company/store/" + company, {
        headers: headers,
      })
      .toPromise();
  }

  // GET ONE STORE
  getOne(code): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "imerbackend-store/one/" + code, { headers: headers })
      .toPromise();
  }

  // GET PAYMET FOR STORE
  getPaymets(storeCode): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "imerbackend-store/storeGetPaymet/" + storeCode, { headers: headers })
      .toPromise();
  }

  // GET PAYMET FOR STORE
  getSalesPerson(storeCode, type): Promise<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .get(this.url + "imerbackend-store/storeGetSalesPerson/" + storeCode + "/" + type, { headers: headers })
      .toPromise();
  }

  // INSERT STORE
  insert(store: Store): Promise<any> {
    let params = JSON.stringify(store);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(this.url + "imerbackend-store", params, { headers: headers })
      .toPromise();
  }

  // UPDATE STORE
  update(store: Store): Promise<any> {
    let params = JSON.stringify(store);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .put(this.url + "imerbackend-store", params, { headers: headers })
      .toPromise();
  }

  //GUID FOR A PRESALE
  getGUID(identificador): Promise<any> {
    let body = JSON.stringify(identificador);
    console.log(body);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(
        "https://us-central1-clever-375004.cloudfunctions.net/getUUID",
        body,
        { headers }
      )
      .toPromise();
  }

  //UPLOAD PRESALE JSON FILE
  uploadFile(invoice, guid, pdfHmtl, correlativo, carpeta): Promise<any> {
    
    console.log('cargando...');
    console.log('cargando...',invoice);
    console.log('cargando...', guid);
    console.log('cargando...',pdfHmtl);
    console.log('cargando...',correlativo);

    try {

      const encoder = new TextEncoder();
      let htmlenc = encoder.encode(pdfHmtl);
      
      let body = JSON.stringify({
        bucket_name: "nail-center",
        // carpeta: "PreventasFCAM",
        carpeta: carpeta,
        filename: guid + ".html",
        // content: btoa(pdfHmtl),
        content: btoa( String.fromCharCode(...htmlenc) ),
        correlativo,
        content_type: "text/html",
        invoice : invoice
      });
      console.log('cargando',body);
      let headers = new HttpHeaders().set("Content-Type", "application/json");
      return this._http
        .post(
          "https://us-central1-clever-375004.cloudfunctions.net/upload_File",
          body,
          { headers }
        )
        .toPromise();
      
    } catch (error) {
      
      console.log('error...',error);
      
    }    
  }

  //SEARCH PRESALE JSON FILE
  searchPresale(carpeta): Promise<any> {
    let body = JSON.stringify({
      bucket_name: "nail-center",
      // folder_name: "PreventasFCAM/",
      folder_name: carpeta + "/",
    });
    console.log('search',body);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post(
        "https://us-central1-clever-375004.cloudfunctions.net/get_folder",
        body,
        { headers }
      )
      .toPromise();
  }
}