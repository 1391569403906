import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import * as printJS from 'print-js';
import { Store } from 'src/app/models/store';
import { Client } from "src/app/models/client";
import { Company } from "src/app/models/company";
import { StoreService } from 'src/app/services/store.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { InvoiceHeader } from '../../../../models/invoiceHeader';
import { InvoiceDetail } from '../../../../models/invoiceDetail';
import { InvoiceService } from 'src/app/services/invoice.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LetrasPipe } from 'src/app/pipe/letras/letras.pipe';
import { UtilsService } from 'src/app/services/utils.service';
import { PrintService } from "src/app/services/print.service";
import swal from "sweetalert2";
import * as xlsx from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: "app-reventa",
  templateUrl: "./preventa.component.html",
  styleUrls: ["./preventa.component.scss"],
  providers: [
    ClientService,
    StoreService,
    CompanyService,
    InventoryService,
    InvoiceService,
    WarehouseService,
    UtilsService,
  ],
})
export class PreventaComponent implements OnInit {
  // Variables auxiliares
  pipeLetra = new LetrasPipe();
  pipeFecha = new DatePipe("en-US");
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public nowDay: string;
  public userCode: number;
  public userName: string;
  public storeCode: number;
  public storeName: string;
  public companyCode: number;
  public activeFiles = {};
  public inactiveFiles = {};
  public billingFiles = {};
  public presaleUpdate: boolean;
  public tipoPago: number;
  public temp = [];
  public correlativoUpdate = 0;
  public fileNameUpdate = "";
  public invoiceUpdate: any;
  loading;

  public isBeginning: boolean;
  public viewNIT: boolean;
  public isBilling: boolean;
  public isPrintT: boolean;
  public isPrintL: boolean;
  public isEdit: boolean;
  public isDisabled: boolean;
  public recurrentClient: boolean;
  public insertNewClient: boolean = false; //crear cliente si no existe

  // DATA SELECT
  public id: number;
  public itemName: string;
  public selectedItems = [];
  public inventoryList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;

  // DATA SELECT SERIAL
  public selectedItemsSerial = [];
  public serialList: any[] = new Array();
  public dropdownSettingsSerial: IDropdownSettings = {};
  closeDropdownSelectionSerial = false;
  public serialSelected = new Array();
  public serialSelectedTemp = new Array();

  // Variables de Linea de Compra
  public itemCode: string;
  public stock: number;
  public moneda: string;
  public invoiceLine: any = new Array();
  public invoiceLineOld: any = new Array();
  public addAmount: boolean;
  public idp: number;
  public subIdp: number;
  public subsidio: number;
  public subsidioTemp: number;
  public isOil: number;
  public petroleo: string;
  public taxOilCode: number;
  public taxOilAmount: number;
  public dte: any;
  public fel: any;
  public felDetail: any;
  public priceType: number;
  public companyLogoUrl: string;
  public taxToPay: number = 0;
  public regimenMessage: string = "";

  //FACTURAR DESDE CLIENT
  public code: number = 0;
  public url: any;
  activatedRoute: ActivatedRoute | null | undefined;

  //FACTURA DESDE TRANSFERENCIA
  public isParamsUrl: boolean = false;
  public transferCode: any;
  public transferDetail: any[];
  public total: number;

  //Prueba de impresion
  public infile: any;
  public megaprint: any;

  public disabled = false;
  public disabledEXT = false;
  public facturando: boolean;
  public tipoCliente = 0;
  public activeRow: any;
  public Minstock: number;

  public entries: number = 3;
  public entriesInv: number = 5;

  public active = [];
  public inactive = [];
  public billed = [];

  public settings = {
    columns: {
      correlativo: {
        title: 'correlativo'
      },

      identificador: {
        title: 'identificador'
      },
      creacion: {
        title: 'Costo',
        type: 'creacion'
      }      
    }
  };

  // DATA SELECT PAYMENT
  public idPayment: number;
  public itemNamePayment: string;
  public selectedItemsPayment = [];
  public PaymentList: any[] = new Array();
  public dropdownSettingsPayment: IDropdownSettings = {};
  closeDropdownSelectionPayment = false;
  
  public PaymentLine: any = new Array;
  public PaymentType = 0;
  public NamePaymentType = 0;
  public MontoPayment: number = 0.00;
  public MontoTotalPayment: number = 0.00;
  public Autorizacion: string = "0";
  public disabledPay = false;
  public isDisabledInvoice = false;  
  public isMethodPay = false;

  // DATA SELECT SALES PERSON
  public selectedItemsSalesP = [];
  public SalesPList: any[] = new Array();
  public dropdownSettingsSalesP: IDropdownSettings = {};
  //public dropdownSettingsSalesP = {};
  closeDropdownSelectionSalesP = false;
  public SalesPerson = 0;

  //Clientes
  public entriesClients: number = 5;
  public selected: any[] = [];
  public tempClients = [];
  public activeRowClients: any;
  public clientList: Client[] = new Array();
  public settingsClients = {
    columns: {
      clientBusinessCode: {
        title: 'Código'
      },
      clientName: {
        title: 'Nombre'
      },
      clientTaxDocument: {
        title: 'Nit'
      },
      clientNameFrequent: {
        title: 'Nombre Cliente Frecuente'
      },
      clientPhone: {
        title: 'Teléfono'
      },
      clientAddress: {
        title: 'Dirección'
      },
      clientCreditTime: {
        title: 'Días de Crédito'
      },
      clientCreditAmount: {
        title: 'Monto de Crédito',
        type: 'number'
      }
    }
  };

  constructor(
    private _utilsService: UtilsService,
    private _printService: PrintService,
    private _storeService: StoreService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _invoiceService: InvoiceService,
    private _inventoryService: InventoryService,
    private _warehouseService: WarehouseService,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private _pipeNumero: DecimalPipe
  ) {}

  async ngOnInit() {
    this.presaleUpdate = false;
    this.addAmount = true;
    this.isEdit = false;
    this.isDisabled = false;
    this.disabled = false;
    this.jumpSerial = false;
    this.insertNewClient = false;
    this.idp = 0;
    this.subIdp = 0;
    this.subsidio = 0;
    this.subsidioTemp = 0;
    this.taxToPay = 0;
    this.moneda = "Q";
    this.priceType = 1;
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceHeader.invoiceIDP = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCurrency = "GTQ";
    this.invoiceDetail.invoiceDetailType = "B";
    this.invoiceDetail.invoiceDetailQuantity = 1;
    //this.invoiceHeader.invoiceInternSerial = "A";
    this.invoiceHeader.paymentType = 0;
    this.tipoPago = 0;
    this.invoiceHeader.invoiceExchangeRate = 0;

    this.nowDay = this._utilsService.dateTime("yyy-MM-dd");
    this.invoiceHeader.invoiceDate = this.nowDay;

    // GET ID USER & ID COMPANY
    const _data = JSON.parse(localStorage.getItem("data"));
    this.storeName = _data.storeName ? _data.storeName.toString() : null;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.companyCode = _data.companyCode
      ? parseInt(_data.companyCode.toString())
      : 0;
    const _user = JSON.parse(localStorage.getItem("user"));
    this.userName = _user.userName ? _user.userName.toString() : "";
    this.invoiceHeader.invoiceInternSerial = _user.userSerial ? _user.userSerial.toString() : 'A';
    this.SalesPerson = this.userCode;

    this.viewBeginning();
    // this.searchPresale();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getPaymetList(this.storeCode);
    await this.getSalesPList(this.storeCode);
    await this.getInventoryList(this.storeCode);
    this.getSelectSetting(this.selectedItems);
    this.getSelectSettingSerial(this.selectedItemsSerial);
    this.getSelectSettingPaymet(this.selectedItemsPayment);
    this.getSelectSettingSalesP(this.selectedItemsSalesP);

    // console.log('Select -> ', this.selectedItems);
    // console.log('Select Tipo pagos -> ', this.selectedItemsPayment);
    // console.log('Tipo pagos -> ', this.PaymentList);

    this.Pagado();

    this.url = this.router.parseUrl(this.router.url);
    if (this.url.queryParams["nit"] != undefined) {
      this.isParamsUrl = true;
      this.code = this.url.queryParams["nit"];
      await this.buscarNITLocal(event, this.code);

      if (this.url.queryParams["transfer"]) {
        let transfer = this.url.queryParams["transfer"];
        await this.cargarTrasnferencia(transfer);
      }
    }
    this.SKUx = "";
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.disabledEXT = true;
    this.disabled = false;
    //this.getAll(this.storeCode); //this.storeCode
  }

  async onItemSelectPaymet(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItemPayment = await this.PaymentList.find(x => x.id == item.id);
    console.log(_selectedItemPayment);

    this.tipoPago = item.payMethodBusinessCode;
    console.log('Tipo Pago -> ', this.tipoPago);

    this.PaymentType = this.tipoPago;
    this.NamePaymentType = item.payMethodName;

    // this.itemCode = item.idPayment;
    // this.invoiceDetail.invoiceDetailDescription = item.itemNamePayment;
    // this.stock = _selectedItem.inventoryQuantity;
    // this.Minstock = _selectedItem.inventoryMin;
  }

  getPaymetList(code) {
    return this._storeService.getPaymets(code).then(
      response => {
        if (response.result) {
          this.PaymentList = response.records;
          // console.log("this.inventoryList ->" + code);
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getSelectSettingPaymet(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? this.selectedItemsPayment = [selected] : '';

    this.dropdownSettingsPayment = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'payMethodBusinessCode',
      textField: 'payMethodName'
    };
  }

  async addPaymetDetail(){

    this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment + (this.MontoPayment))).toFixed(6));
    console.log(this.MontoTotalPayment);

    await this.PaymentLine.push({
      typePayment: this.PaymentType,
      NamePaymentType: this.NamePaymentType,
      MontoPayment: this.MontoPayment,
      Autorizacion: this.Autorizacion
    });

    this.MontoPayment = 0;
    this.Autorizacion = "0";
    this.selectedItemsPayment = [];

    this.Pagado();

    console.log(this.PaymentLine);
  }

  async deletePaymetDetail(item) {

    await this.PaymentLine.forEach(async (e, index) => {
      if (e.typePayment === item) {

        this.MontoTotalPayment = parseFloat(((this.MontoTotalPayment - (e.MontoPayment))).toFixed(6));        
        this.PaymentLine.splice(index, 1);
      }
    })

    this.Pagado();
        
    console.log(this.PaymentLine);
  }

  async Pagado(){

    if(this.company.companyPaymentType == 1){
      this.isMethodPay = true;
      if((this.MontoTotalPayment == this.invoiceHeader.invoiceTotal) && this.MontoTotalPayment > 0){
        this.isDisabledInvoice = false;
      }
      else if(this.MontoTotalPayment > this.invoiceHeader.invoiceTotal){
        this.isDisabledInvoice = true;
        this._utilsService.showNotification(2,"Total pagado es mayor al Total de la factura")
      }
      else{
        this.isDisabledInvoice = true;
      }
    }
    else{
      this.isMethodPay = false;
      this.isDisabledInvoice = false;
    }
  }

  /////////////////////AGREGAR LINEA DESCRIPCION AL DETALLE FACTURA///////////////////////////
  saveLineInvoiceDesc() {
    if (this.invoiceDetail.invoiceDetailDescription != "" && this.invoiceDetail.invoiceDetailQuantity > 0 && this.invoiceDetail.invoiceDetailUnitPrice > 0) {
      //Aumentar Codigo si no viene uno
      // if (this.itemCode == "I") {
      //   this.itemNumberCode++;
      //   this.itemCode += this.itemNumberCode;
      // }

      //Calcular subtotal de linea
      this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice;

      if (this.invoiceDetail.invoiceDetailDiscount != undefined && this.invoiceDetail.invoiceDetailDiscount != null && this.invoiceDetail.invoiceDetailDiscount > 0) {
        this.invoiceDetail.invoiceDetailSubtotal = this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailDiscount;
        this.invoiceDetail.invoiceDetailTax = this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay);
      } else {
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }

      //Actualizar
      // this.invoiceLine.forEach((e) => {
      //   console.log('actualiza descripcion');
      //   if (e.itemCode == this.itemCode) {

      //     this.invoiceHeader.invoiceTotal -= e.total;

      //     e.itemCode = 0;
      //     e.measure = "UNI";
      //     e.taxCode = 1;
      //     e.taxName = "IVA";
      //     e.isService = this.invoiceDetail.invoiceDetailType;
      //     e.quantity = parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(6));
      //     e.description = this.invoiceDetail.invoiceDetailDescription;
      //     e.unitPrice = parseFloat((this.invoiceDetail.invoiceDetailUnitPrice).toFixed(6));
      //     e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
      //     e.discount = parseFloat((this.invoiceDetail.invoiceDetailDiscount).toFixed(6));
      //     e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(6));
      //     e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(6));
      //     e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(6));

      //     // this.total = this.total + this.subTotal;
      //     this.addAmount = false;
      //   }
      // })

      //Insertar
      if (this.addAmount == true) {
        console.log('Inserta descripcion');
        this.invoiceLine.push({
          subsidio: this.subsidioTemp,
          isOil: this.isOil,
          taxOilUP: this.idp,
          taxOilAmount: this.subIdp,
          taxOilName: this.petroleo,
          taxOilCode: this.taxOilCode,
          taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,          
          itemCode: 0,//this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: "B",
          quantity: parseFloat(this.invoiceDetail.invoiceDetailQuantity.toFixed(4)),
          description: this.invoiceDetail.invoiceDetailDescription,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal + this.invoiceDetail.invoiceDetailDiscount).toFixed(4)),
          discount: parseFloat(this.invoiceDetail.invoiceDetailDiscount.toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4))
        });
      }
      // console.log(this.invoiceLine);
      // Sumar Total
      this.invoiceHeader.invoiceTotal += this.invoiceDetail.invoiceDetailSubtotal;
      this.invoiceHeader.invoiceTax += this.invoiceDetail.invoiceDetailTax;
      this.cleanLineInvoice();
    }

    this.ValidarCF();
    this.Pagado();

    this.isDisabled = false;
  }

  // CALCULAR IMPUESTO DE LINEA
  calculateTax() {
    let subTotalLine;

    subTotalLine = (this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice);

    if (this.invoiceDetail.invoiceDetailDiscount > 0) {
      if (this.invoiceDetail.invoiceDetailDiscount <= subTotalLine) {
        subTotalLine = subTotalLine - this.invoiceDetail.invoiceDetailDiscount;
      } else {
        this._utilsService.showNotification(2, "Cantidad No permitida");
        this.invoiceDetail.invoiceDetailDiscount = 0;
      }
    }

    let tax;
    tax = (subTotalLine - (subTotalLine / this.taxToPay)).toFixed(6);
    this.invoiceDetail.invoiceDetailTax = parseFloat(tax);
  }

    /////////////////////FUNCTIONS SALES PERSONS/////////////////////////////////
    getSalesPList(code) {    
      return this._storeService.getSalesPerson(code,2).then(
        response => {
          if (response.result) {
            this.SalesPList = response.records;
            //console.log('vendedores', this.SalesPList);
            // console.log("this.inventoryList ->" + code);
          } else {
            console.log(response.message);
          }
        },
        error => {
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  
    async onItemSelectSalesP(item: any) {
      console.log("---item---");
      console.log(item);
      const _selectedItemSalesP = await this.SalesPList.find(x => x.id == item.id);
      console.log(_selectedItemSalesP);
  
      this.SalesPerson = item.salesPersonCodeUser;
      console.log('SalesPerson -> ', this.SalesPerson);
    }
  
    getSelectSettingSalesP(selected) {
      // console.log(selected);
      Object.keys(selected).length > 0 ? this.selectedItemsSalesP = [selected] : '';
  
      this.dropdownSettingsSalesP = {
        singleSelection: true,
        searchPlaceholderText: 'Buscar...',
        closeDropDownOnSelection: true,
        allowSearchFilter: true,
        idField: 'salesPersonCodeUser',
        textField: 'salesPersonName'
      };
  
      // this.dropdownSettingsSalesP = {
      //   singleSelection: true,
      //   text: "Seleccione...",
      //   searchPlaceholderText: 'Buscar',
      //   noDataLabel: "Resultados...",
      //   enableCheckAll: false,
      //   enableSearchFilter: true,
      //   lazyLoading: true,
      //   idField: 'salesPersonCodeUser',
      //   textField: 'salesPersonName',
      //   searchBy: ['salesPersonCodeUser', 'salesPersonName']
      // };
    }

  public SKUx: string;
  async CargarSKU() {
    console.log("----Cargar inventory---", this.inventoryList);
    var sku;
    sku = this.SKUx;
    //alert(sku);

    const SKUs = this.inventoryList.find(
      ({ inventoryBusinessCode }) => inventoryBusinessCode === sku
    );
    console.log("SKU Buscado", SKUs);
    //console.log('SKU Buscado STOCK', SKUs.stock);

    if (SKUs == undefined) {
      this._utilsService.showNotification(
        2,
        "Producto no disponible, validar stock en inventario"
      );
    } else {
      if (sku.length > 3) {
        //const _selectedItem = await this.inventoryList.find(x => x.id == sku);
        const _selectedItem = SKUs;
        console.log("----_selectedItem---", _selectedItem);

        //SI ES PETROLIO
        this.idp = 0;
        this.subIdp = 0;
        this.subsidioTemp = 0;
        this.isOil = null;
        this.petroleo = null;
        if (_selectedItem.inventoryProduct == 3) {
          this.isOil = 1;
          this.idp = 4.7;
          this.taxOilCode = 1;
          this.petroleo = "PETROLEO";
        } else if (_selectedItem.inventoryProduct == 4) {
          this.idp = 4.6;
          this.subsidioTemp = 2.5;
          this.taxOilCode = 2;
          this.petroleo = "PETROLEO";
        } else if (_selectedItem.inventoryProduct == 5) {
          this.idp = 1.3;
          this.subsidioTemp = 5;
          this.taxOilCode = 4;
          this.petroleo = "PETROLEO";
        }

        this.itemCode = _selectedItem.inventoryCode;
        this.invoiceDetail.invoiceDetailDescription = _selectedItem.itemName;
        this.invoiceDetail.invoiceDetailType = "B";
        this.stock = _selectedItem.inventoryQuantity;
        this.Minstock = _selectedItem.inventoryMin;

        if (_selectedItem.inventorySerialFlag == 1) {
          this.loading = true;
          this.serialSelectedTemp = [];
          this.serialList = [];
          const response = await this._inventoryService.getItemSerial(
            this.itemCode
          );
          this.serialList = response.records[1];

          this.serial = true;
          this.loading = false;
        } else {
          this.serial = false;
        }

        await this.saveLineInvoice();
        this.SKUx = "";
      }
    }
  }

  public jumpSerial: boolean;
  async cargarTrasnferencia(code) {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    await this._warehouseService.getOneTransfer(code).then((response) => {
      this.transferCode = response.records[0].transferCode;
      this.transferDetail = response.records[0].detail;
    });

    for (const e of this.transferDetail) {
      const _selectedItem = await this.inventoryList.find(
        (x) => x.id == parseFloat(e.transferInventoryDetailInventoryCode)
      );
      this.invoiceDetail.invoiceDetailType = "B";
      this.itemCode = e.transferInventoryDetailInventoryCode;
      this.invoiceDetail.invoiceDetailQuantity = parseFloat(
        e.transferInventoryDetailQuantity
      );
      this.invoiceDetail.invoiceDetailDescription =
        _selectedItem.inventoryBusinessCode +
        " - " +
        e.transferInventoryDetailDescription;
      this.stock = _selectedItem.inventoryQuantity;
      this.priceType = 1;

      // Nuevo
      if (parseFloat(e.transferDetailType) === 1) {
        await this.saveLineInvoice();
      } else {
        let series = await this._warehouseService.getOneSerial(
          this.transferCode,
          e.transferInventoryDetailInventoryCode
        );
        for (const i of series.records[0].detail) {
          await this.serialSelected.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription,
          });
          await this.serialSelectedTemp.push({
            serialCode: parseFloat(i.trasferSerialDetailSerialCode),
            inventorySerialSerial: i.trasferSerialDetailDescription,
          });
        }
        this.jumpSerial = true;
        await this.saveLineInvoiceSerial();
        this.serialSelectedTemp = [];
      }
      this.jumpSerial = true;
    }

    this.loading = false;
    this.isDisabled = false;
    this.disabled = false;
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChangeInv($event) {
    this.entriesInv = $event.target.value;
  }

  filterTableInv($event) {
    let val = $event.target.value.toLowerCase();
    this.temp = this.inventoryList.filter(function (d) {
      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

   ///////////////////////FUNCTION FOR TABLE///////////////////////
    entriesChange($event) {
      this.entries = $event.target.value;
    }

    filterTable($event) {
      let val = $event.target.value.toLowerCase();
      this.activeFiles = this.active.filter(function (d) {
        for (var key in d) {
          if (d[key] !== null) {
            if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
              return true;
            }
          }
        }
        return false;
      });
    }

    entriesChangeInactivas($event) {
      this.entries = $event.target.value;
    }

    filterTableInactivas($event) {
      let val = $event.target.value.toLowerCase();
      this.inactiveFiles = this.inactive.filter(function (d) {
        console.log(d.correlativo);
        console.log('val',val);
        for (var key in d) {
          console.log('key',key);
          if (d[key] !== null) {
            if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
              return true;
            }
          }
        }
        return false;
      });
    }

    entriesChangeFacturadas($event) {
      this.entries = $event.target.value;
    }

    filterTableFacturadas($event) {
      let val = $event.target.value.toLowerCase();
      this.billingFiles = this.billed.filter(function (d) {
        for (var key in d) {
          if (d[key] !== null) {
            if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
              return true;
            }
          }
        }
        return false;
      });
    }

    onActivateInactivas(event) {
      this.activeRow = event.row;
    }

    onActivateActivas(event) {
      this.activeRow = event.row;
    }

    onActivateFacturadas(event) {
      this.activeRow = event.row;
    }

    ngAfterContentChecked() {
      this.cdref.detectChanges();
    }

    // DESCARGAR EXCEL
  async downloadToExcel() {
    const checkList = this.active;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-inventario.xlsx');
  }

  async downloadToExcelInactivas() {
    const checkList = this.inactive;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-inventario.xlsx');
  }

  async downloadToExcelFacturadas() {
    const checkList = this.inactive;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-inventario.xlsx');
  }

  // GET ALL CLIENT BY COMPANY
  getAllByCompany() {
    this.loading = true;
    //console.log('Cargar Clientes -> ', this.companyCode);
    return this._clientService.getAllByCompany(this.companyCode).then(
      response => {
        if (response.result) {
          //console.log(response.records);
          this.clientList = response.records;
          this.tempClients = this.clientList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  ///////////////////////FUNCTION FOR TABLE///////////////////////
  entriesChangeClients($event) {
    this.entries = $event.target.value;
  }

  filterTableClients($event) {
    let val = $event.target.value.toLowerCase(); 
    this.tempClients = this.clientList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivateClients(event) {
    this.activeRow = event.row;
  }

  ngAfterContentCheckedClients() {
    this.cdref.detectChanges();
  }

  viewFunctionClient($event) {
    $event.preventDefault();

    swal.fire({
      title: "Empresa:",
      html:
        '<div class="text-left">' +
        '<b>Nombre: </b>' + this.activeRow.clientName + '<br>' +
        '<b>Teléfono: </b>' + this.activeRow.clientPhone + '<br>' +
        '<b>Correo: </b>' + this.activeRow.clientEmail + '<br>' +
        '<b>Dirección: </b>' + this.activeRow.clientAddress + '<br></div>',
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false
    })
  }

  // DESCARGAR EXCEL Clientes
  async downloadToExcelClients() {
    const checkList = this.tempClients;
    const exportFile = new Array<any>();

    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settingsClients.columns).forEach((item, index, arr) => {
        if (this.settingsClients.columns[item].type === "number") {
          json[this.settingsClients.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settingsClients.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte-cliente.xlsx');
  }

  billingClient($event) {
    $event.preventDefault();
    swal.fire({
      title: 'Nota',
      text: "Se abrira una nueva preventa",
      icon: "warning",
      width: 750,
      showCancelButton: true,
      showDenyButton: false,
      confirmButtonText: 'Preventa',
      //denyButtonText: 'Fatura Cambiaria',
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success",
        //denyButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isDismissed) {
        //console.log('candelado')
        //console.log(this.company);
        console.log(this.activeRow);        
      } else {
        
        if (result.isConfirmed) {
          this.client.clientType = this.activeRow.clientType;
          this.buscarNITLocal(event, this.activeRow.nit);          
          // window.open(this.url + billingType + '-billing?nit=' + this.activeRow.nit);
        } else if (result.isDenied) {
          // window.open(this.url + billingType + '-exchange-note?nit=' + this.activeRow.nit);
        }
      }
    })
  }

  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      (response) => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12
            ? (this.taxToPay = 1.12)
            : (this.taxToPay = 1.05);
          this.company.companyTaxToPay == 12
            ? (this.regimenMessage = "Sujeto a pagos trimestrales ISR")
            : (this.regimenMessage = "*No genera derecho a crédito fiscal");
          this.company.companyLogo != "undefined"
            ? (this.companyLogoUrl = this.company.companyLogoUrl)
            : "";
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      (response) => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting(selected) {
    // console.log(selected);
    Object.keys(selected).length > 0 ? (this.selectedItems = [selected]) : "";

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: "Buscar...",
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: "id",
      textField: "itemName",
    };
  }

  getSelectSettingSerial(selected) {
    Object.keys(selected).length > 0
      ? (this.selectedItemsSerial = [selected])
      : "";

    this.dropdownSettingsSerial = {
      idField: "InventorySerialCode",
      textField: "inventorySerialSerial",
      itemsShowLimit: 3,
      enableCheckAll: false,
      singleSelection: false,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
    };
  }  

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      closeDropDownOnSelection: this.closeDropdownSelection,
    });
  }

  getInventoryList(code) {
    this.inventoryList = [];
    return this._inventoryService
      .getAllByStore(code)
      .then(
        (response) => {
          if (response.result) {
            this.inventoryList = response.records;
            //console.log("this.inventoryList ->" + code);
            // console.log(this.inventoryList);
          } else {
            console.log(response.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }

  serial = false;
  async onItemSelect(item: any) {
    console.log("---item---");
    console.log(item);
    const _selectedItem = await this.inventoryList.find((x) => x.id == item.id);

    //SI ES PETROLIO
    this.idp = 0;
    this.subIdp = 0;
    this.subsidioTemp = 0;
    this.isOil = null;
    this.petroleo = null;
    if (_selectedItem.inventoryProduct == 3) {
      this.isOil = 1;
      this.idp = 4.7;
      this.taxOilCode = 1;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 4) {
      this.idp = 4.6;
      this.subsidioTemp = 2.5;
      this.taxOilCode = 2;
      this.petroleo = "PETROLEO";
    } else if (_selectedItem.inventoryProduct == 5) {
      this.idp = 1.3;
      this.subsidioTemp = 5;
      this.taxOilCode = 4;
      this.petroleo = "PETROLEO";
    }

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailDescription = item.itemName;
    this.invoiceDetail.invoiceDetailType = "B";
    this.stock = _selectedItem.inventoryQuantity;
    this.Minstock = _selectedItem.inventoryMin;

    if (_selectedItem.inventorySerialFlag == 1) {
      this.loading = true;
      this.serialSelectedTemp = [];
      this.serialList = [];
      const response = await this._inventoryService.getItemSerial(
        this.itemCode
      );
      this.serialList = response.records[1];

      this.serial = true;
      this.loading = false;
    } else {
      this.serial = false;
    }
  }

  onItemSelectSerial(item: any) {
    this.serialSelected.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial,
    });

    this.serialSelectedTemp.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial,
    });
  }

  async onItemDeSelectSerial(item: any) {
    this.serialSelected.forEach((e, index) => {
      if (e.serialCode == item.InventorySerialCode)
        this.serialSelected.splice(index, 1);
    });

    this.serialSelectedTemp.forEach((i, indece) => {
      if (i.serialCode == item.InventorySerialCode)
        this.serialSelectedTemp.splice(indece, 1);
    });
  }

  ///////////////////////FUNCTION CHANGE COIN///////////////////////
  onChange(event) {
    this.invoiceHeader.invoiceCoin = event;

    if (this.invoiceHeader.invoiceCoin == 0) {
      this.moneda = "Q";
      this.invoiceHeader.invoiceCurrency = "GTQ";
      this.invoiceHeader.invoiceExchangeRate = 0;
    } else if (this.invoiceHeader.invoiceCoin == 1) {
      this.moneda = "$";
      this.invoiceHeader.invoiceCurrency = "USD";
    }
  }

  ///////////////////////FUNCTION CHANGE PAYMENT TYPE///////////////////////
  onChangepaymentType(event) {
    this.invoiceHeader.paymentType = event;
    this.tipoPago = event;
    //console.log(this.tipoPago);
  }

  ///////////////////////FUNCTION CHANGE PRICE///////////////////////
  onChangePrice(event) {
    this.priceType = parseFloat(event);
  }

  ///////////////////////FUNCTION VALIDATE DATE///////////////////////
  validateDate($event) {
    $event.preventDefault();
    var valid = false;
    var now = this.nowDay.split("-");
    var date = this.invoiceHeader.invoiceDate.split("-");

    if (date[0] == now[0] || parseFloat(date[0]) == parseFloat(now[0]) - 1) {
      if (date[1] == now[1]) {
        if (
          parseFloat(date[2]) <= parseFloat(now[2]) + 5 &&
          parseFloat(date[2]) >= parseFloat(now[2]) - 5
        ) {
          valid = true;
        }
      } else if (
        parseFloat(date[1]) == parseFloat(now[1]) - 1 ||
        parseFloat(date[1]) == 12
      ) {
        if (parseFloat(date[2]) >= 26) {
          valid = true;
        }
      }
    }

    if (valid == false) {
      this._utilsService.showNotification(2, "Fecha fuera de rango");
      this.invoiceHeader.invoiceDate = this.nowDay;
    }
  }

  ///////////////////////FUNCTION CHANGE INTERAL SERIAL///////////////////////
  onChangeInternSerial(event) {
    this.invoiceHeader.invoiceInternSerial = event;
  }

  // GUARDAR UNA LINEA DE COMPRA
  async saveLineInvoice() {
    this.isDisabled = true;
    this.disabled = true;

    if (this.invoiceDetail.invoiceDetailQuantity > 0) {
      //Actualizar?
      let update = false;
      console.log("---invoiceLine---");
      console.log(this.invoiceLine);
      console.log("---invoiceDetail---");
      console.log(this.invoiceDetail);
      console.log("---itemCode---");
      console.log(this.itemCode);
      for await (const e of this.invoiceLine) {
        if (e.itemCode == this.itemCode) {
          //Validacion
          let quantity;
          this.isEdit === true
            ? (quantity = this.invoiceDetail.invoiceDetailQuantity)
            : (quantity =
                e.quantity + this.invoiceDetail.invoiceDetailQuantity);

          const response = await this._inventoryService.getPriceByItem(
            this.itemCode,
            quantity
          );
          switch (this.priceType) {
            case 1:
              this.invoiceDetail.invoiceDetailUnitPrice =
                response.records.precio;
              break;
            case 2:
              this.invoiceDetail.invoiceDetailUnitPrice =
                response.records.inventoryPriceDiscount;
              break;
            case 3:
              this.invoiceDetail.invoiceDetailUnitPrice =
                response.records.inventorySalePrice;
              break;
            case 4:
              this.invoiceDetail.invoiceDetailUnitPrice =
                response.records.inventoryminprice;
              break;
          }

          if (
            this.company.companyExistenceFlag === 1 ||
            quantity <= this.stock
          ) {
            //Restar Encabezado
            this.subsidio -= e.subsidio;
            this.invoiceHeader.invoiceIDP -= parseFloat(
              e.taxOilAmount.toFixed(4)
            );
            this.invoiceHeader.invoiceTotal -= parseFloat(e.total.toFixed(4));
            this.invoiceHeader.invoiceTax -= parseFloat(e.taxAmount.toFixed(4));

            //Realizar Calculos
            if (parseFloat(e.discount) > 0) {
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat(
                (quantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(
                  4
                )
              );
              this.invoiceDetail.invoiceDetailSubtotal = parseFloat(
                (this.invoiceDetail.invoiceDetailSubtotal - e.discount).toFixed(
                  4
                )
              );
              this.invoiceDetail.invoiceDetailTax = parseFloat(
                (
                  this.invoiceDetail.invoiceDetailSubtotal -
                  this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay
                ).toFixed(4)
              );
            } else {
              this.invoiceDetail.invoiceDetailDiscount = e.discount;
              this.invoiceDetail.invoiceDetailSubtotal =
                quantity * this.invoiceDetail.invoiceDetailUnitPrice;
              this.invoiceDetail.invoiceDetailTax = parseFloat(
                (
                  this.invoiceDetail.invoiceDetailSubtotal -
                  this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay
                ).toFixed(4)
              );
            }

            //Editar Linea
            e.quantity = quantity;
            e.unitPrice = this.invoiceDetail.invoiceDetailUnitPrice;
            e.price = parseFloat(
              this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)
            );
            e.taxAmount = parseFloat(
              this.invoiceDetail.invoiceDetailTax.toFixed(4)
            );
            e.taxableAmount = parseFloat(
              (
                this.invoiceDetail.invoiceDetailSubtotal -
                this.invoiceDetail.invoiceDetailTax
              ).toFixed(4)
            );
            (e.taxOilAmount = parseFloat((quantity * this.idp).toFixed(4))),
              (e.total = parseFloat(
                (
                  this.invoiceDetail.invoiceDetailSubtotal +
                  parseFloat((quantity * this.idp).toFixed(4))
                ).toFixed(4)
              )),
              (e.taxOilQuantity = quantity);
            e.subsidio = quantity * this.subsidioTemp;

            //Sumar Encabezado
            this.subsidio += e.subsidio;
            this.invoiceHeader.invoiceIDP += parseFloat(
              (quantity * this.idp).toFixed(4)
            );
            this.invoiceHeader.invoiceTotal += parseFloat(
              (
                this.invoiceDetail.invoiceDetailSubtotal +
                quantity * this.idp
              ).toFixed(4)
            );
            this.invoiceHeader.invoiceTax += parseFloat(
              this.invoiceDetail.invoiceDetailTax.toFixed(4)
            );
            this.selectedItems = [];
            this.cleanLineInvoice();
          } else {
            this._utilsService.showNotification(2, "Cantidad Excede Exitencia");
          }
          update = true;
          this.isEdit = false;
          this.addAmount = false;
          this.isDisabled = false;
          this.disabled = false;
        }
      }

      //Nuevo Item
      if (this.addAmount === true && update === false) {
        const response = await this._inventoryService.getPriceByItem(
          this.itemCode,
          this.invoiceDetail.invoiceDetailQuantity
        );
        switch (this.priceType) {
          case 1:
            this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventoryPriceDiscount;
            break;
          case 3:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventorySalePrice;
            break;
          case 4:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventoryminprice;
            break;
        }

        this.subIdp = parseFloat(
          (this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(4)
        );
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat(
          (
            this.invoiceDetail.invoiceDetailQuantity *
            this.invoiceDetail.invoiceDetailUnitPrice
          ).toFixed(4)
        );
        this.invoiceDetail.invoiceDetailTax = parseFloat(
          (
            this.invoiceDetail.invoiceDetailSubtotal -
            this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay
          ).toFixed(4)
        );

        //Validacion
        if (this.company.companyExistenceFlag === 1 || this.invoiceDetail.invoiceDetailQuantity <= this.stock) 
        {

          let DifInventario = this.stock - this.invoiceDetail.invoiceDetailQuantity;

          console.log(DifInventario, this.stock, this.invoiceDetail.invoiceDetailQuantity);
          console.log(this.Minstock);   

          if(DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }

          //Guardar Linea
          await this.invoiceLine.push({
            //Area de combustible
            subsidio: this.subsidioTemp,
            isOil: this.isOil,
            taxOilUP: this.idp,
            taxOilAmount: this.subIdp,
            taxOilName: this.petroleo,
            taxOilCode: this.taxOilCode,
            taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,
            itemCode: this.itemCode,
            measure: "UNI",
            taxCode: 1,
            taxName: "IVA",
            isService: this.invoiceDetail.invoiceDetailType,
            quantity: this.invoiceDetail.invoiceDetailQuantity,
            description: this.invoiceDetail.invoiceDetailDescription,
            discount: 0,
            unitPrice: parseFloat(
              this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)
            ),
            price: parseFloat(
              this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)
            ),
            taxAmount: parseFloat(
              this.invoiceDetail.invoiceDetailTax.toFixed(4)
            ),
            taxableAmount: parseFloat(
              (
                this.invoiceDetail.invoiceDetailSubtotal -
                this.invoiceDetail.invoiceDetailTax
              ).toFixed(4)
            ),
            total: parseFloat(
              (
                this.invoiceDetail.invoiceDetailSubtotal +
                this.invoiceDetail.invoiceDetailQuantity * this.idp
              ).toFixed(4)
            ),
          });
          this.subsidio +=
            this.invoiceDetail.invoiceDetailQuantity * this.subsidioTemp;
          this.invoiceHeader.invoiceIDP += parseFloat(
            (this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(4)
          );
          this.invoiceHeader.invoiceTotal += parseFloat(
            (
              this.invoiceDetail.invoiceDetailSubtotal +
              parseFloat(
                (this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(4)
              )
            ).toFixed(4)
          );
          this.invoiceHeader.invoiceTax += parseFloat(
            this.invoiceDetail.invoiceDetailTax.toFixed(4)
          );
          this.selectedItems = [];
          this.cleanLineInvoice();
        } else {
          this._utilsService.showNotification(2, "Cantidad Excede Exitencia");
        }
        this.isDisabled = false;
        this.disabled = false;
      }
    } else {
      this._utilsService.showNotification(2, "Ingresa una cantidad");
    }
    this.addAmount = true;
    this.isDisabled = false;
    this.disabled = false;
    this.ValidarCF();
    this.Pagado();
  }

  // GUARDAR LINEA CON SERIES
  async saveLineInvoiceSerial() {
    this.isDisabled = true;
    this.disabled = true;

    let res_find = await this.invoiceLine.find(
      (x) => x.itemCode === this.itemCode
    );

    if (!res_find) {
      if (
        this.invoiceDetail.invoiceDetailQuantity ==
        this.serialSelectedTemp.length
      ) {
        if (!this.jumpSerial) {
          this.invoiceDetail.invoiceDetailDescription += " SERIES: ";
          for await (const e of this.serialSelectedTemp) {
            this.invoiceDetail.invoiceDetailDescription +=
              e.inventorySerialSerial + ", ";
          }
        }

        const response = await this._inventoryService.getPriceByItem(
          this.itemCode,
          this.invoiceDetail.invoiceDetailQuantity
        );
        switch (this.priceType) {
          case 1:
            this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventoryPriceDiscount;
            break;
          case 3:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventorySalePrice;
            break;
          case 4:
            this.invoiceDetail.invoiceDetailUnitPrice =
              response.records.inventoryminprice;
            break;
        }

        this.subIdp = 0;
        this.invoiceDetail.invoiceDetailSubtotal = parseFloat(
          (
            this.invoiceDetail.invoiceDetailQuantity *
            this.invoiceDetail.invoiceDetailUnitPrice
          ).toFixed(4)
        );
        this.invoiceDetail.invoiceDetailTax = parseFloat(
          (
            this.invoiceDetail.invoiceDetailSubtotal -
            this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay
          ).toFixed(4)
        );

        await this.invoiceLine.push({
          //Area de Serie
          serial: this.serialSelectedTemp,
          //Area de combustible
          isOil: this.isOil,
          taxOilUP: this.idp,
          taxOilAmount: this.subIdp,
          taxOilName: this.petroleo,
          taxOilCode: this.taxOilCode,
          taxOilQuantity: this.invoiceDetail.invoiceDetailQuantity,
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: this.invoiceDetail.invoiceDetailQuantity,
          description: this.invoiceDetail.invoiceDetailDescription,
          discount: 0,
          unitPrice: parseFloat(
            this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)
          ),
          price: parseFloat(
            this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)
          ),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat(
            (
              this.invoiceDetail.invoiceDetailSubtotal -
              this.invoiceDetail.invoiceDetailTax
            ).toFixed(4)
          ),
          total: parseFloat(
            (
              this.invoiceDetail.invoiceDetailSubtotal +
              this.invoiceDetail.invoiceDetailQuantity * this.idp
            ).toFixed(4)
          ),
        });
        this.invoiceHeader.invoiceIDP += parseFloat(
          (this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(4)
        );
        this.invoiceHeader.invoiceTotal += parseFloat(
          (
            this.invoiceDetail.invoiceDetailSubtotal +
            parseFloat(
              (this.invoiceDetail.invoiceDetailQuantity * this.idp).toFixed(4)
            )
          ).toFixed(4)
        );
        this.invoiceHeader.invoiceTax += parseFloat(
          this.invoiceDetail.invoiceDetailTax.toFixed(4)
        );
        this.cleanLineInvoice();
      } else {
        this._utilsService.showNotification(
          2,
          "Por favor revisa las cantidad de series seleccionadas"
        );
      }
    } else {
      this._utilsService.showNotification(
        3,
        "Item ya ingresado, por favor eliminalo antes."
      );
    }
    this.isDisabled = false;
    this.disabled = false;
  }

  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        let DifInventario = this.stock - e.quantity;

          if(DifInventario <= this.Minstock){
            this._utilsService.showNotification(2, 'El STOCK MINIMO ha llegado a su limite configurado.');
            //enviar correo
          }

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = parseFloat(
            (this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(4)
          );
          this.invoiceHeader.invoiceTax = parseFloat(
            (this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(4)
          );
          e.total = parseFloat((temporal - parseFloat(descuento)).toFixed(4));
          e.taxAmount = parseFloat(
            (parseFloat(e.total) - parseFloat(e.total) / this.taxToPay).toFixed(
              4
            )
          );
          e.taxableAmount = parseFloat(
            (parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4)
          );
          this.invoiceHeader.invoiceTotal = parseFloat(
            (this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(4)
          );
          this.invoiceHeader.invoiceTax = parseFloat(
            (this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(4)
          );
        } else {
          this._utilsService.showNotification(2, "Cantidad No permitida");
        }
      }
    });

    this.ValidarCF();
  }

  ApplyQuantity($event, item, cantidad) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (cantidad > 0) {
          this.invoiceHeader.invoiceTotal = parseFloat(
            (this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(4)
          );
          this.invoiceHeader.invoiceTax = parseFloat(
            (this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(4)
          );
          e.total = parseFloat(temporal.toFixed(4));
          e.taxAmount = parseFloat(
            (parseFloat(e.total) - parseFloat(e.total) / this.taxToPay).toFixed(
              4
            )
          );
          e.taxableAmount = parseFloat(
            (parseFloat(e.total) - parseFloat(e.taxAmount)).toFixed(4)
          );
          this.invoiceHeader.invoiceTotal = parseFloat(
            (this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(4)
          );
          this.invoiceHeader.invoiceTax = parseFloat(
            (this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(4)
          );
        } else {
          this._utilsService.showNotification(2, "Cantidad No permitida");
        }
      }
    });

    this.ValidarCF();
  }

  // ELIMINAR UNA LINEA
  async deleteLine(item) {
    this.isDisabled = true;
    this.disabled = true;

    await this.invoiceLine.forEach(async (e, index) => {
      if (e.itemCode === item) {
        if (e.taxOilName != undefined) {
          this.subsidio -= parseFloat(e.subsidio);
          this.invoiceHeader.invoiceIDP =
            this.invoiceHeader.invoiceIDP - parseFloat(e.taxOilAmount);
        }

        if (e.serial) {
          for (const i of e.serial) {
            let indice = this.serialSelected.findIndex(
              (x) => x.serialCode === i.serialCode
            );
            this.serialSelected.splice(indice, 1);
          }
        }

        this.invoiceHeader.invoiceTotal = parseFloat(
          (this.invoiceHeader.invoiceTotal - (parseFloat(e.price)*e.quantity)).toFixed(4)
        );
        this.invoiceHeader.invoiceTax = parseFloat(
          (this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(4)
        );
        this.invoiceLine.splice(index, 1);
      }
    });
    this.cleanLineInvoice();
    this.isDisabled = false;
    this.disabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.jumpSerial = false;
    this.isDisabled = false;
    this.disabled = false;
    this.addAmount = true;
    this.priceType = 1;
    this.isEdit = false;
    this.serial = false;
    this.selectedItemsSerial = null;
    this.selectedItems = [];
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceDetail.invoiceDetailQuantity = 1;

    // if(this.presaleUpdate){
    //   this.cancelPresaleUpdate()
    // }
  }

  onChangeTypeClient(event) {
    this.client.clientType = event;
    this.tipoCliente = event;
    console.log(this.tipoCliente);

    if (event == 2) {
      this.disabledEXT = false;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    } else {
      this.disabledEXT = true;
      this.client.clientName = "";
      this.client.clientTaxDocument = "";
    }
  }

  ///////////////////////Funcion de Buscar por NIT///////////////////////
  buscarNITLocal($event, nit) {
    $event.preventDefault();
    this.isDisabled = true;
    this.disabled = true;
    this.loading = true;
    //this.invoiceLine = []

    if (this.client.clientType == 2) {
      this.client.clientName = this.client.clientName;
      this.client.clientStatus = 1;
      this.client.clientDeleted = 0;
      this.client.clientPhone = "00000000";
      this.client.clientEmail = "jearson.developer11@gmail.com";
      this.client.clientAddress = "CIUDAD";

      this.insertNewClient = false;
      this.recurrentClient = false;
      this.viewBilling();
      this._utilsService.showNotification(
        4,
        "SE EMITIRA FACTURA CON PASAPORTE"
      );
      this.loading = false;
      this.client.clientType = 2;
      this.tipoCliente = 2;

      this.isDisabled = false;
    } else {
      if (nit == "CF") {
        this.client.clientType = 0;
        this.tipoCliente = 0;
      }
      // return this._clientService.getOneByNit(nit).then(
      return this._clientService
        .getOneByCompanyNit(this.companyCode, nit)
        .then(
          (response) => {
            // console.log(response.records)
            if (response.records.length > 0) {
              this.client = response.records[0];
              this.client.clientEmail == '' ? 'jearson.developer11@gmail.com' : this.client.clientEmail;
              this.recurrentClient = true;
              this.insertNewClient = false;
              this.viewBilling();
              this._utilsService.showNotification(1, response.message);
              console.log('cliente',this.client);
            } else {
              return this._clientService
                .searchNitSAT(nit, this.client.clientType)
                .then(
                  async (response) => {
                    if (response.tipo_respuesta == 0) {
                      //this.client.clientName = response.nombre[0];
                      //console.log(response.nombre[0]);
                      this.client.clientName = response.nombre[0];
                      this.client.clientStatus = 1;
                      this.client.clientDeleted = 0;
                      this.client.clientPhone = "00000000";
                      this.client.clientEmail = "jearson.developer11@gmail.com";
                      this.client.clientAddress = this.client.clientAddress;
                      this.insertNewClient = true;
                      this.recurrentClient = false;
                      this.viewBilling();
                      this._utilsService.showNotification(4, "Cliente Nuevo");
                    } else {
                      this._utilsService.showNotification(
                        3,
                        response.listado_errores[0].error[0].desc_error[0]
                      );
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          this.loading = false;
          this.isDisabled = false;

          this.ValidarCF();
        });
    }
    scrollTo(0,0);
  }

  async saveInvoice() {
    this.loading = true;
    this.isDisabled = true;
    this.disabled = true;

    if(this.client.clientEmail == "" || this.SalesPerson == 0){

      this._utilsService.showNotification(3,"Campo de correo de cliente y vendedor son obligatorios");
      this.loading = false;

    }
    else{

      console.log('Actualizando-> 1 ',this.presaleUpdate);
      let identificador = "Mi_nombre";
      let guid;

      if (this.insertNewClient) {
        // Resolver problema con DATA BASE
        let nombre;
        nombre = await this.client.clientName.replace(/&/g, '&amp;');
        nombre = await this.client.clientName.replace(/'/g, '&apos;');
        nombre = await this.client.clientName.replace(/"/g, '&quot;');

        this.client.clientName = nombre;
        this.client.clientCredit = 0;
        this.client.clientStatus = 1;
        this.client.clientDeleted = 0;
        this.client.clientBlocked = 0;
        this.client.clientInterest = 0;
        this.client.clientDiscount = 0;
        this.client.clientCategory = 2;
        this.client.clientCreditTime = 0;
        this.client.clientCreditAmount = 0;
        this.client.clientCompanyCode = this.companyCode;
        this.client.clientType = 2;
        this.client.clientNameFrequent = '-';

        if (this.client.clientTaxDocument && this.client.clientName) {
          const response = await this._clientService.insert(this.client);
          this.client.clientCode = response.records[0].insertId;
        };
      }

      if(this.company.companyPaymentType == 0){
        await this.PaymentLine.push({
          typePayment: 0,
          NamePaymentType: 'Efectivo',
          MontoPayment: parseFloat((this.invoiceHeader.invoiceTotal).toFixed(4))
        });
      }
      
      //RETORNA GUID A UTILIZAR PARA LA PREVENTA
      this._storeService
        .getGUID({ identificador })
        .then(
          async (response) => {
            if (response.guid) {
              console.log('guid',response.guid);
              guid = response.guid;              

              // Si es factura de pequeño contribuyente
              let transmitterType = "FACT";
              let transmitterMembership = "GEN";
              if (this.company.companyTaxToPay == 5) {
                transmitterType = "FPEQ";
                transmitterMembership = "PEQ";
              }

              if (this.invoiceHeader.invoiceIDP > 0) {
                this.invoiceHeader.invoiceTax = 0;
                this.invoiceLine.forEach((e) => {
                  this.invoiceHeader.invoiceTax = parseFloat(
                    (this.invoiceHeader.invoiceTax + e.taxAmount).toFixed(4)
                  );
                });
              } else {
                this.invoiceHeader.invoiceTax = parseFloat(
                  (
                    (this.invoiceHeader.invoiceTotal / this.taxToPay) *
                    (this.taxToPay - 1)
                  ).toFixed(4)
                );
              }

              const hour = this._utilsService.dateTime("HH:mm:ss");

              const json = {
                store: {
                  storeCode: this.storeCode,
                },
                user: {
                  userCode: this.userCode,
                },
                transmitter: {
                  transmitterMembership: transmitterMembership,
                  transmitterType: transmitterType,
                  transmitterEstablishmentCode: this.store.storeEstablishmentCode,
                  transmitterEmail: this.company.companyEmail,
                  transmitterTaxDocument: this.company.companyTaxDocument,
                  transmitterComercialName: this.store.storeName,
                  transmitterName: this.company.companyName,
                  transmitterAddress: this.store.storeAddress,
                  transmitterZipCode: this.store.storeZipCode,
                  transmitterMunicipality: this.store.storeMunicipality,
                  transmitterDepartment: this.store.storeDepartment,
                  transmitterCountry: "GT",
                  transmitterPrefix: this.company.companyPrefix,
                  transmitterUser: this.company.companyPrefix,
                  transmitterKey: this.company.companyKey,
                  transmitterToken: this.company.companyToken,
                  transmitterPhraseCode: parseFloat(
                    this.company.companyPhraseCode
                  ),
                  transmitterPhraseType: parseFloat(
                    this.company.companyPhraseType
                  ),
                },
                receiver: {
                  receiverAddress: this.client.clientAddress,
                  receiverCode: this.client.clientCode,
                  receiverDeleted: 0,
                  receiverEmail: this.client.clientEmail,
                  receiverName: this.client.clientName,
                  receiverStatus: 1,
                  receiverTaxDocument: this.client.clientTaxDocument,
                  receiverTypeTaxDocument: this.tipoCliente,
                  receiverZipCode: 0,
                  receiverCountry: "GT",
                  receiverPhone: this.client.clientPhone,
                  receiverPhone2: this.client.clientPhone2,
                },
                invoice: {
                  invoiceCode: 1,
                  invoiceCurrency: this.invoiceHeader.invoiceCurrency,
                  invoiceChangeRate: this.invoiceHeader.invoiceExchangeRate == undefined ? 0 : this.invoiceHeader.invoiceExchangeRate,
                  invoiceTaxShortName: "IVA",
                  // "invoiceDate": this.invoiceHeader.invoiceDate,
                  invoiceDate: this.invoiceHeader.invoiceDate + " " + hour,
                  invoiceInternSerial: this.invoiceHeader.invoiceInternSerial,
                  invoiceTaxValue: parseFloat(
                    this.invoiceHeader.invoiceTax.toFixed(4)
                  ),
                  invoiceTotal: parseFloat(
                    this.invoiceHeader.invoiceTotal.toFixed(4)
                  ),
                  invoiceOilTaxShortName:
                    this.invoiceHeader.invoiceIDP > 0 ? "PETROLEO" : undefined,
                  invoiceOilTaxValue: parseFloat(
                    this.invoiceHeader.invoiceIDP.toFixed(4)
                  ),
                  invoicepaymentType: this.tipoPago,
                  invoiceDuesQuantity: 0,
                },
                phrase: this.company.phrase,
                detail: this.invoiceLine,
                paymentType: this.PaymentLine,
                provider:
                  this.company.companyCertifier == 0 ? "infile" : "megaprint",
                serial: this.serialSelected,
                salesPerson: this.SalesPerson,
                preventa: 1,
              };

              console.log(json);

              console.log('Actualizando-> 2',this.presaleUpdate);
              await this._storeService.searchPresale("Preventas").then(
                async (response) => {
                  if (response) {
                    
                    console.log('response search', response);

                    let correlativo = 0
                    !this.presaleUpdate ? correlativo = response.folder_result.length + 1
                    : correlativo = this.correlativoUpdate

                    console.log('correlativo 1', correlativo);
                    console.log('Company', this.company);

                    const pdfHmtl = await this._printService.prevent_print(
                      json,
                      json.detail,
                      this.company,
                      correlativo,
                      this.PaymentLine,
                      this.client                      
                    );

                    if(!this.presaleUpdate)
                    {
                      console.log('--inicia carga--');
                      await this._storeService
                      .uploadFile(
                        JSON.stringify(json),
                        guid,
                        pdfHmtl,
                        correlativo,
                        "Preventas"
                      )
                      .then(
                        async (response) => {
                          if (response.message) {
                            this._utilsService.showNotification(
                              1,
                              "Preventa " +
                                correlativo +
                                " guardada correctamente!"
                            );

                            const pdfWindow = window.open();
                            pdfWindow.document.title = "Preventa";
                            pdfWindow.document.write(pdfHmtl);

                            //ENVIAR PREVENTA POR CORREO
                            const data_to_send = {
                              email: this.client.clientEmail,
                              presale_number: correlativo,
                              html_content: pdfHmtl,
                            };

                            if (this.client.clientEmail !== "") {
                              this._invoiceService
                                .sendEmail_Presale(data_to_send)
                                .then(
                                  async (response) => {
                                    if (response) {
                                      this._utilsService.showNotification(
                                        1,
                                        "Preventa No. " +
                                          correlativo +
                                          " enviada correctamente"
                                      );
                                    }
                                  },
                                  (error) => {
                                    console.log(error);
                                    //this.loading = false;
                                    //this.disabled = false;
                                  }
                                );
                            }

                            await this._inventoryService.updateStock(this.invoiceLine, "R");
                            this.loading = false;
                            this.disabled = false;

                            this.viewBeginning();
                            this.cleanModel();
                            // this.searchPresale();
                          }
                        },
                        (error) => {
                          console.log(error);
                          this.loading = false;
                          this.disabled = false;
                          this._utilsService.showNotification(
                            3,
                            "Error al guardar preventa!"
                          );
                        }
                      );
                    }
                    else{
                      await this._invoiceService
                      .updatePresale(
                        this.fileNameUpdate,
                        guid,
                        JSON.stringify(json),
                        pdfHmtl,
                        correlativo,
                        "Preventas"
                      )
                      .then(
                        async (response) => {
                          console.log(response.message);
                          if (response.message) {
                            this._utilsService.showNotification(
                              1,
                              "Preventa " +
                                correlativo +
                                " actualizada correctamente!"
                            );

                            console.log('genera pdf');
                            const pdfWindow = window.open();
                            pdfWindow.document.title = "Preventa";
                            pdfWindow.document.write(pdfHmtl);

                            //ENVIAR PREVENTA POR CORREO
                            const data_to_send = {
                              email: this.client.clientEmail,
                              presale_number: correlativo,
                              html_content: pdfHmtl,
                            };

                            if (this.client.clientEmail !== "") {
                              this._invoiceService
                                .sendEmail_Presale(data_to_send)
                                .then(
                                  async (response) => {
                                    if (response) {
                                      this._utilsService.showNotification(
                                        1,
                                        "Preventa No. " +
                                          correlativo +
                                          " enviada correctamente"
                                      );
                                    }
                                  },
                                  (error) => {
                                    console.log(error);
                                    //this.loading = false;
                                    //this.disabled = false;
                                  }
                                );
                                console.log('envia correo');
                            }

                            console.log('invoicesactive',this.invoiceUpdate);
                            console.log('toma invoice');
                            //let invoice = JSON.parse(this.activeRow.metadata.invoice);
                            let invoice = this.invoiceUpdate;
                            console.log('Invoice Original -> ',invoice);
                            console.log('Invoice Detail Original -> ', invoice.detail);

                            console.log('Detalle Anterior -> ', this.invoiceLineOld);
                            console.log('Detalle nuevo -> ', this.invoiceLine);

                            console.log('actualiza inventario');
                            await this._inventoryService.updateStock(invoice.detail, "I");
                            await this._inventoryService.updateStock(this.invoiceLine, "R");

                            this.loading = false;
                            this.disabled = false;
                            this.isBeginning = true;

                            console.log('Limpia');
                            this.viewBeginning();
                            this.cleanModel();
                            // this.searchPresale();
                          }
                        },
                        (error) => {
                          console.log(error);
                          this.loading = false;
                          this.disabled = false;
                          this._utilsService.showNotification(
                            3,
                            "Error al actualizar preventa! -> " + error.message
                          );
                        }
                      );
                      //this.presaleUpdate = false;
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          this.loading = false;
          this.isDisabled = false;
          this.disabled = false;
        });

    }
    
  }

  async searchPresale() {
    this.loading = true;
    this.disabled = true;

    await this.getInventoryList(this.storeCode);

    await this._storeService.searchPresale("Preventas").then(
      async (response) => {
        if (response) {
          const allFiles = response.folder_result;
          this.active = [];
          this.inactive = [];
          this.billed = [];

          allFiles.forEach((file) => {

            this.alertPresaleExpiration(file)

            if (file.metadata.active == "true") {
              
              let date = moment(file.creacion, "DD/MM/YYYY HH:mm:ss").subtract(6,'h').format("DD/MM/YYYY HH:mm:ss");

              let presaleInvoice = JSON.parse(file.metadata.invoice);
              let cliente = presaleInvoice.receiver.receiverTaxDocument;
              let clienteName = presaleInvoice.receiver.receiverName;

              //console.log( cliente, clienteName );
              file.creacion = date;
              file.clientTax = cliente;
              file.clientName = clienteName;
              this.active.push(file);              

            } else if (file.metadata.active == "false") {

              let date = moment(file.creacion, "DD/MM/YYYY HH:mm:ss").subtract(6,'h').format("DD/MM/YYYY HH:mm:ss");

              let presaleInvoice = JSON.parse(file.metadata.invoice);
              let cliente = presaleInvoice.receiver.receiverTaxDocument;
              let clienteName = presaleInvoice.receiver.receiverName;

              //console.log( cliente, clienteName );
              file.creacion = date;
              file.clientTax = cliente;
              file.clientName = clienteName;
              this.inactive.push(file);

            } else if (file.metadata.active == "billed") {

              let date = moment(file.creacion, "DD/MM/YYYY HH:mm:ss").subtract(6,'h').format("DD/MM/YYYY HH:mm:ss");

              let presaleInvoice = JSON.parse(file.metadata.invoice);
              let cliente = presaleInvoice.receiver.receiverTaxDocument;
              let clienteName = presaleInvoice.receiver.receiverName;

              //console.log( cliente, clienteName );
              file.creacion = date;
              file.clientTax = cliente;
              file.clientName = clienteName;
              this.billed.push(file);

            }            
          });

          this.activeFiles = this.active;
          this.activeFiles = this.active.sort(function (a, b) {
            if ((a.correlativo * 1) > (b.correlativo * 1)) {
              return 1;
            }
            if ((a.correlativo * 1) < (b.correlativo * 1)) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.inactiveFiles = this.inactive;
          this.inactiveFiles = this.inactive.sort(function (a, b) {
            if ((a.correlativo * 1) > (b.correlativo * 1)) {
              return 1;
            }
            if ((a.correlativo * 1) < (b.correlativo * 1)) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.billingFiles = this.billed;
          this.billingFiles = this.billed.sort(function (a, b) {
            if ((a.correlativo * 1) > (b.correlativo * 1)) {
              return 1;
            }
            if ((a.correlativo * 1) < (b.correlativo * 1)) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.inactiveFiles = this.inactive;
          this.billingFiles = this.billed;
          this.loading = false;
          this.disabled = false;

          console.log(this.active);
          console.log(this.inactive);
        }
      },
      (error) => {
        console.log(error);
        //this.loading = false;
        //this.disabled = false;
      }
    );
  }

  // SI LA PREVENTA LLEVA +36 HRS DE HABER SIDO CREADA, LANZA UNA NOTIFICACIÓN
  alertPresaleExpiration(presaleFile){
    // Hoy
    let now = new Date()

    // console.log(presaleFile);
    // console.log(JSON.parse(presaleFile.metadata.active));

    let activa = presaleFile.metadata.active;
    //console.log('Estado -> ', activa);

    // Fecha de creacion
    let fecha = presaleFile.creacion.split(' ')[0]
    let hora = presaleFile.creacion.split(' ')[1]
    let creationDate = new Date(fecha.split('/')[2], fecha.split('/')[1] - 1, fecha.split('/')[0], hora.split(':')[0], hora.split(':')[1])
    
    var diff = (now.getTime() - creationDate.getTime()) / 1000
    diff /= (60 * 60)
    
    if(diff > 24 && activa == "true"){
      this._utilsService.showNotification(2, "La preventa No. " + presaleFile.correlativo + " lleva más de 24 hrs. activa y no ha sido facturada");
    }
  }

  async insertInvoice($event) {
    $event.preventDefault();
    const _data = JSON.parse(localStorage.getItem('data'));

    if(true){
      swal.fire({
        title: "¿Está seguro?",
        text: `La preventa No. ${this.activeRow.correlativo} se facturará`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        confirmButtonText: "Ok",
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.loading = true;
          this.isDisabled = true;
          this.disabled = true;
          

          let invoice = JSON.parse(this.activeRow.metadata.invoice);

          console.log(invoice);
      
          if (this.company.companyCertifier == 0) {
            //INFILE
            this._invoiceService
              .insertBilling(invoice)
              .then(
                (response) => {
                  if (response.result) {
                    // console.log(response);
                    if (this.company.companyPDFType == 1) {
                      this.fel = response.infileBody;
                      this.felDetail = response.infileBody.detail;
                      this.dte = {
                        serie:
                          response.records.infileServiceResponse.registerResponse
                            .serie,
                        noFactura:
                          response.records.infileServiceResponse.registerResponse
                            .numero,
                        autorizacion: response.invoiceAuthorization,
                        certificador: "INFILE, S.A.",
                        nitCertificador: 12521337,
                        fechaCertificacion:
                          response.records.infileServiceResponse.registerResponse
                            .fecha,
                      };
                      this.viewPrint();
                    } else {
                      let uuid = response.invoiceAuthorization;
                      window.open(
                        "https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=" +
                          uuid,
                        "popup",
                        "width=600,height=600"
                      );
                      this.viewBeginning();
                      this.cleanModel();
                    }
                    this._utilsService.showNotification(1, response.message);
                    
                    this._invoiceService.changeStatusPresale(this.activeRow.nombre, "billed", "Preventas").then(
                      async (response) => {
                          if (response) {
                            this.loading = false;
                            this.disabled = false;
                            await this.searchPresale();
                          }
                      },
                      (error) => {
                        console.log(error);
                        //this.loading = false;
                        //this.disabled = false;
                      }
                    );
                  }
                  else {
                    this._utilsService.showNotification(3, response.errorMessage);
                  }
                },
                (error) => {
                  this._utilsService.showNotification(2, error);
                  console.log(error);
                }
              )
              .finally(() => {
                this.loading = false;
                this.isDisabled = false;
                this.disabled = false;
              });
          }
          else if (this.company.companyCertifier == 1) {
            //Megaprint
            this._invoiceService
              .insertBilling(invoice)
              .then(
                (response) => {
                  if (response.result) {
                    // console.log(response);
                    if (
                      this.company.companyInvoiceFuel == 1 ||
                      this.company.companyPDFType == 1
                    ) {
                      this.fel = response.megaprintBody;
                      this.felDetail = response.megaprintBody.detail;
                      let dte =
                        response.records.megaprintServiceResponse.jsonXml[
                          "dte:GTDocumento"
                        ]["dte:SAT"][0]["dte:DTE"][0]["dte:Certificacion"][0];
                      this.dte = {
                        serie: dte["dte:NumeroAutorizacion"][0]["Serie"][0],
                        noFactura: dte["dte:NumeroAutorizacion"][0]["Numero"][0],
                        autorizacion: dte["dte:NumeroAutorizacion"][0]["_"],
                        certificador: dte["dte:NombreCertificador"][0],
                        nitCertificador: dte["dte:NITCertificador"][0],
                        fechaCertificacion: dte["dte:FechaHoraCertificacion"][0],
                      };
                      this.viewPrint();
                    } else {
                      var pdfResult = response.invoicePdf;
                      let pdfWindow = window.open("");
                      pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                          encodeURI(pdfResult) +
                          "'></iframe>"
                      );
                      this.viewBeginning();
                      this.cleanModel();
                    }
                    this._utilsService.showNotification(1, response.message);
                    this._invoiceService
                      .changeStatusPresale(this.activeRow.nombre, "billed", "Preventas")
                      .then(
                        async (response) => {
                          if (response) {
                            this.loading = false;
                            this.disabled = false;
                            await this.searchPresale();
                          }
                        },
                        (error) => {
                          console.log(error);
                          //this.loading = false;
                          //this.disabled = false;
                        }
                      );
                  }
                  else {
                    this._utilsService.showNotification(3, response.errorMessage);
                  }
                },
                (error) => {
                  this._utilsService.showNotification(2, error);
                  console.log(error);
                }
              )
              .finally(() => {
                this.loading = false;
                this.isDisabled = false;
                this.disabled = false;
              });
          }

          swal.fire({
            title: "Confirmado!",
            text: `Se ha facturado la preventa No. ${this.activeRow.correlativo}`,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });

      this.getAll(this.storeCode);

      var amountsByPayType = JSON.parse(localStorage.getItem('salesAmounts'));
      this.PaymentLine.forEach(element => {
        amountsByPayType.paymentType.forEach(payType => {
          console.log(amountsByPayType.paymentType)
          if(payType.id == element.typePayment){
            payType.amount += element.MontoPayment
          }
        });
      });

      localStorage.setItem('salesAmounts', JSON.stringify(amountsByPayType))
    }else{
      swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se puede realizar una venta hasta que abra la caja....',
      })
    }
  }

  async updatePresale($event){
    $event.preventDefault();
    this.presaleUpdate = true;
    this.invoiceLine = [];
    //this.PaymentLine = [];

    this.correlativoUpdate = this.activeRow.correlativo;
    this.fileNameUpdate = this.activeRow.nombre;

    let presaleInvoice = await JSON.parse(this.activeRow.metadata.invoice);
    this.invoiceUpdate = await JSON.parse(this.activeRow.metadata.invoice);
    this.invoiceLineOld = presaleInvoice.detail;


    console.log(presaleInvoice);
    console.log('Detalle original -> ',this.invoiceLineOld);
    console.log('Forma de pago -> ', presaleInvoice.paymentType);
    
    this.client.clientAddress = presaleInvoice.receiver.receiverAddress
    this.client.clientCode = presaleInvoice.receiver.receiverCode
    this.client.clientEmail = presaleInvoice.receiver.receiverEmail
    this.client.clientName = presaleInvoice.receiver.receiverName
    this.client.clientTaxDocument = presaleInvoice.receiver.receiverTaxDocument
    this.client.clientPhone = presaleInvoice.receiver.receiverPhone
    this.client.clientPhone2 = presaleInvoice.receiver.receiverPhone2
    this.tipoCliente = presaleInvoice.receiver.receiverTypeTaxDocument
    this.client.clientType = presaleInvoice.receiver.receiverTypeTaxDocument

    this.client.clientEmail = presaleInvoice.receiver.receiverEmail
    this.client.clientAddress = presaleInvoice.receiver.receiverAddress


    this.invoiceLine = presaleInvoice.detail
    this.invoiceHeader.invoiceTotal = presaleInvoice.invoice.invoiceTotal

    console.log(presaleInvoice.invoice.invoiceInternSerial);
    this.invoiceHeader.invoiceInternSerial = presaleInvoice.invoice.invoiceInternSerial;

    // if(presaleInvoice.salesPerson != undefined && presaleInvoice.salesPerson > 0){
    //   let selectedItemsSalesPx = await this.SalesPList.find(x => x.salesPersonCodeUser == presaleInvoice.salesPerson);
    //   //this.selectedItemsSalesP = [presaleInvoice.salesPerson];
    //   this.getSelectSettingSalesP(selectedItemsSalesPx);
    //   //this.PaymentLine = presaleInvoice.PaymentType    
    // }
    
    console.log('Actualizando Clic Boton->',this.presaleUpdate);
    this.viewPresale();
    //this.viewBeginning();
  }

  async cancelPresaleUpdate(){
    console.log('Actualizando->',this.presaleUpdate);
    this.presaleUpdate = false;
    this.priceType = 1;
    console.log('Actualizando->',this.presaleUpdate);
  }

  async deletePresale($event) {
    $event.preventDefault();

    swal
      .fire({
        title: "¿Está seguro?",
        text: `La preventa No. ${this.activeRow.correlativo} pasará a estatus inactivo definitivamente`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        confirmButtonText: "Si",
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.loading = true;
          this.disabled = true;

          this._invoiceService
            .changeStatusPresale(this.activeRow.nombre, "false", "Preventas")
            .then(
              async (response) => {
                if (response) {
                  this.loading = false;
                  this.disabled = false;

                  swal.fire({
                    title: "Confirmado!",
                    text: `Se ha cambiado el estatus de la preventa No. ${this.activeRow.correlativo}`,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                  });

                  let invoice = JSON.parse(this.activeRow.metadata.invoice);
                  console.log(this.activeRow);
                  console.log('Invoice -> ',invoice);
                  console.log('Invoice Detail -> ', invoice.detail);

                  await this._inventoryService.updateStock(invoice.detail, "I");

                  await this.searchPresale();
                }
              },
              (error) => {
                console.log(error);
                //this.loading = false;
                //this.disabled = false;
              }
            );
        }
      });
  }

  // GET ALL INVENTORY
  getAll(storeCode) {
    this.loading = true;
    // console.log(storeCode, "este es el store code para nail center")
    return this._inventoryService.getAllByStore(storeCode).then(
      response => {
        if (response.result) {
          this.inventoryList = response.records;
          this.temp = this.inventoryList;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  
  // GET ONE INVENTORY
  viewFunction($event) {
    $event.preventDefault();
    let series;
    this.activeRow.inventorySerialFlag == 1 ? series = 'SI' : series = 'NO';

    swal.fire({
      title: "Item:",
      imageUrl: `https://storage.googleapis.com/nail-center/Imagenes/${this.activeRow.inventoryBusinessCode}.jfif`,
      imageHeight: 300,
      html:
        '<div class="text-left">' +
        "<b>Código: </b>" +
        this.activeRow.inventoryBusinessCode +
        "<br>" +
        "<b>Nombre: </b>" +
        this.activeRow.inventoryName +
        "<br>" +
        "<b>Series: </b>" +
        series +
        "<hr>" +
        "<b>Precio Venta: </b>" +
        this.activeRow.inventoryPrice +
        "<br>" +
        "<b>Precio Minimo de Venta: </b>" +
        this.activeRow.inventoryMinPrice +
        "<br>" +
        "<b>Precio con Descuento: </b>" +
        this.activeRow.inventoryPriceDiscount +
        "<br>" +
        "<b>Precio de Promoción: </b>" +
        this.activeRow.inventorySalePrice +
        "<hr>" +
        "<b>Stock: </b>" +
        this.activeRow.inventoryQuantity +
        "<br>" +
        "<b>Stock Mínimo: </b>" +
        this.activeRow.inventoryMin +
        "<br>" +
        "<b>Stock Máximo: </b>" +
        this.activeRow.inventoryMax +
        "<hr>" +
        "<b>Cód. de Orden: </b>" +
        this.activeRow.inventoryPurchaseDetail +
        "<br>" +
        "<b>Descripción: </b>" +
        this.activeRow.inventoryDescription +
        "<br></div>",
      customClass: {
        confirmButton: "btn btn-success",
      },
      width: 650,
      confirmButtonText: "Aceptar",
      buttonsStyling: false,
    });
  }

  async printLetter() {
    const pdfWindow = window.open("", "Factura", "_blank");
    let NCE = "";
    if (this.tipoCliente == 0) {
      NCE = "NIT: ";
    } else if (this.tipoCliente == 1) {
      NCE = "CUI: ";
    } else if (this.tipoCliente == 2) {
      NCE = "EXT: ";
    }

    const css = `<style>   
        td { font-size: 12px; padding: 2px;}     
        .brDescr {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF; border-bottom: 1px solid  #2CA8FF;}
        .brCompl {border-right: 1px solid  #2CA8FF; border-top: 1px solid  #2CA8FF;}
      </style>`;
    var html = `
    <head><title>Factura Clever "${
      this.company.companyName + " - " + this.storeName
    }"</title></head>
    <body style="width: 21cm; height: 27cm; margin: auto; font-family: sans-serif, system-ui, monospace;"> 
      <div style="border: 2px solid #2CA8FF; border-radius: 10px; width: auto;">
        <table style="width: 100%;">
              <tr>
                  <td rowspan="5" style="width: 19%;">
                      <img style="width: 110px; margin:10px auto; display:block;" src="${
                        this.companyLogoUrl
                      }">
                  </td>
                  <th style="width: 62%; font-size: 19px; text-align: cent  er;">                      
                      ${this.fel.transmitter.transmitterComercialName}
                  </th>
                  <td style="width: 19%; font-size: 10px;">
                      Vendedor: ${this.userName}
                  </td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                    ${this.fel.transmitter.transmitterName}
                  </td>
                  <td style="font-size: 10px;">Factura Electronica Fel</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      NIT: ${this.fel.transmitter.transmitterTaxDocument}
                  </td>
                  <td>Serie: ${this.dte.serie}</td>
              </tr>
              <tr>
                  <td style="text-align: center;">
                      Tel: ${this.store.storePhone}<br>
                      ${this.fel.transmitter.transmitterAddress}. ${
      this.fel.transmitter.transmitterMunicipality
    }, ${this.fel.transmitter.transmitterDepartment}
                  </td>
                  <td>No. DTE: ${this.dte.noFactura}</td>
              </tr>
              <tr>
                  <th style="text-align: center;">FACTURA</th>
                  <td>Fecha: ${this.pipeFecha.transform(
                    this.fel.invoice.invoiceDate,
                    "dd-MM-yyy"
                  )}</td>
              </tr>
        </table>
      </div>
      <br>
      <div style="border: 2px solid #2CA8FF; border-radius: 10px 10px 0px 0px; width: auto; min-height: 750px" >
        <table cellspacing="0" cellpadding="0" style="width: 100%;">
            <tr style="max-height: 15px;">
              <td colspan="4"> Nombre: ${this.fel.receiver.receiverName}</td>
              <td colspan="3"> Nit: ${
                this.fel.receiver.receiverTaxDocument
              }</td>
            </tr>
            <tr style="max-height: 15px;">
              <td colspan="7"> Dirección: ${
                this.fel.receiver.receiverAddress
              }</td>
            </tr>
            <tr style="font-size: 11px; text-align: center;">
              <th class="brDescr">CANTIDAD</th>
              <th class="brDescr">TIPO</th>
              <th class="brDescr" style="width: 320px;">DESCRIPCIÓN</th>
              <th class="brDescr">PRECIO UNI.</th>
              <th class="brDescr">IMPUESTO</th>
              <th class="brDescr">DESCUENTO</th>
              <th class="brDescr" style="width: 120px;">TOTAL</th>
            </tr>`;

    for (const e of this.felDetail) {
      html += `<tr">
        <td style="text-align: center; "> ${this._pipeNumero.transform(
          e.quantity,
          "1.2-2"
        )}</td>
        <td style="text-align: center;"> ${e.isService}</td>
        <td>${e.description}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(
          e.unitPrice,
          "1.2-2"
        )}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(
          e.taxAmount,
          "1.2-2"
        )}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(
          e.discount,
          "1.2-2"
        )}</td>
        <td style="text-align: right;"> ${this._pipeNumero.transform(
          e.total,
          "1.2-2"
        )}</td>
        </tr>`;
    }

    html += `          
        </table>
      </div>
      <div style="border: 2px solid #2CA8FF; border-radius: 0px 0px 10px 10px; width: auto;">
        <table cellspacing="0" style="width: 100%;">
              <tr>
                <td colspan="7" style="text-align: center; font-size: 10px;">${
                  this.regimenMessage
                }</td>
              </tr>
              <tr style="height: 30px;">
                <td class="brCompl" style="max-width: 22px; padding-left: 5px;">Total en Letras:</td>
                  <td class="brCompl" style="font-size: 10px max-width: 65px; text-align: left;">${this.pipeLetra.transform(
                    this.fel.invoice.invoiceTotal
                  )}</td>
                  <th style="font-size: 14px; max-width: 65px; text-align: right; border-top: 1px solid  #2CA8FF;">
                    TOTAL: Q ${this._pipeNumero.transform(
                      this.fel.invoice.invoiceTotal,
                      "1.2-2"
                    )}
                  </th>
              </tr>
          </table>
      </div> 
      <br>
      <table style="border: 2px solid #2CA8FF; border-radius: 10px; width: 100%;" padding: 10px;" >
          <tr><td>
            No. de Autorización: ${this.dte.autorizacion}<br>
            Fecha de Certificación: ${this.dte.fechaCertificacion}  
          </td></tr>
          <tr><td style="text-align: center">Certificador: ${
            this.dte.certificador
          }. Nit: ${this.dte.nitCertificador}</td></tr>
      </table>
    </body>`;

    await pdfWindow.document.write(css + html); //setTimeout( () => {pdfWindow.window.open()}, 1100)
    this.continue();
  }

  // Limpiar Campos
  cleanModel() {
    this.addAmount = true;
    this.isDisabled = false;
    this.disabled = false;
    this.insertNewClient = false;
    this.recurrentClient = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.invoiceLine = [];
    this.moneda = "Q";
    this.petroleo = null;
    this.taxOilAmount = 0;
    this.taxOilCode = null;
    this.invoiceHeader.invoiceIDP = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceCurrency = "GTQ";
    this.invoiceDetail.invoiceDetailType = "B";
    this.invoiceHeader.invoiceInternSerial = "A";
    this.invoiceHeader.invoiceDate = this.nowDay;
    this.tipoPago = 0;
    this.invoiceDetail.invoiceDetailQuantity = 1;    
    this.invoiceHeader.paymentType = 0;
    this.invoiceHeader.invoiceExchangeRate = 0;
    this.PaymentLine = [];
    this.MontoTotalPayment = 0;
    this.presaleUpdate = false;
    this.client.clientType = 1;

    if (this.isParamsUrl) {
      this.router.navigate([], { relativeTo: this.activatedRoute });
    }
  }

  ///////////////////////PANTALLAS///////////////////////
  async viewBeginning() {    

    this.isBeginning = true;
    this.viewNIT = true;
    this.isBilling = false;
    this.isPrintT = false;
    this.isPrintL = false;
    console.log("Tipo ", this.client.clientType);
    this.client.clientType = 0;
    this.tipoCliente = 0;
    this.invoiceHeader.invoiceTotal = 0
    this.presaleUpdate = false;

    if(this.presaleUpdate){
      this.cancelPresaleUpdate()
    }

    this.cleanModel();
    await this.getAll(this.storeCode);
    await this.searchPresale();
    await this.getAllByCompany();
    
  }

  viewBilling() {
    this.isBeginning = false;
    this.viewNIT = false;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
    this.client.clientAddress == "" ? 'CIUDAD' : this.client.clientAddress;
    this.client.clientEmail == '' ? 'jearson.developer11@gmail.com' : this.client.clientEmail;
  }

  viewPresale() {
    this.isBeginning = false;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
    this.viewNIT =false;
    // this.client.clientAddress = "";
    // this.client.clientEmail = "";
    console.log('Actualizando->',this.presaleUpdate);
  }

  viewPrint() {
    this.isBeginning = false;
    this.isBilling = false;
    // this.company.companyInvoicePrint === 1 ? this.isPrintT = true : this.isPrintL = true;
    this.company.companyInvoicePrint === 1
      ? (this.isPrintT = true)
      : this.printLetter();
  }

  viewCF() {
    this.isBeginning = false;
    this.viewNIT = true;
    this.isBilling = true;
    this.isPrintT = false;
    this.isPrintL = false;
    this.disabled = true;
    this.isDisabled = true;
    console.log("TP CF-> ", this.tipoCliente);
    this.client.clientType = this.tipoCliente;
  }

  ValidarCF() {
    /*J11 - Validar CF*/

    if (this.invoiceHeader.invoiceTotal >= 2500) {
      if (this.client.clientTaxDocument == "CF") {
        this._utilsService.showNotification(
          5,
          "No puede facturar como CF, el monto es mayor a 2500"
        );
        this.viewCF();
      } else {
        this.disabled = false;
      }
    }
  }

  continue() {
    this.viewBeginning();
    this.cleanModel();
  }

  // PRINT
  printTest() {
    printJS({
      printable: "printSection",
      type: "html",
      style: "#printSection{width: 224px; text-align: center};",
    });
  }
}
